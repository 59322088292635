export default function formatDate(
  dt: Date,
  month?: 'long' | 'numeric' | '2-digit' | 'short' | 'narrow',
  locale = 'en'
): string {
  return dt.toLocaleDateString(locale, {
    month: month || 'long',
    day: 'numeric',
    year: 'numeric',
  });
}
