import { USER_BY_ID } from "data/endpoints";
import { User } from "data/User";
import { getRequest, postRequest } from "utils/baseRequest";

export const getUserById = async (id?: string) => {
  if (!id) {
    return { ok: false, data: undefined };
  }

  try {
    const response = await getRequest<User>(
      USER_BY_ID(id),
    );

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false, data: undefined };
  }
}

export const banUser = async (id?: string) => {
  if (!id) {
    return { ok: false, data: undefined };
  }

  try {
    const response = await postRequest<User>(
      USER_BY_ID(id) + '/ban',
    );

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false, data: undefined };
  }
}

export const unbanUser = async (id?: string) => {
  if (!id) {
    return { ok: false, data: undefined };
  }

  try {
    const response = await postRequest<User>(
      USER_BY_ID(id) + '/unban',
    );

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false, data: undefined };
  }
}
