import { SUBSCRIPTIONS_TIERS } from 'data/endpoints';
import { getRequest } from 'utils/baseRequest';

export interface Tiers {
  subscriptionTierSettings: TiersItem[];
}

export interface TiersItem {
  id: string;
  name: string;
  discount: number;
}

export const getTiers = async () => {
  try {
    const response = await getRequest<Tiers>(SUBSCRIPTIONS_TIERS);

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false, data: undefined };
  }
};
