import { createContext, Dispatch, PropsWithChildren, useContext, useState } from 'react';

type UsersContextType = {
  currPage?: number;
  setCurrPage?: Dispatch<React.SetStateAction<number>>;
};

export const UsersContext = createContext<UsersContextType>({});

const { Provider } = UsersContext;

const UsersContextProvider = (props: PropsWithChildren<Record<string, unknown>>) => {
  const [currPage, setCurrPage] = useState<number>(1);

  return (
    <Provider
      value={{
        currPage,
        setCurrPage
      }}>
      {props.children}
    </Provider>
  );
};

export default UsersContextProvider;

export function useUsersCtx() {
  return useContext(UsersContext);
}
