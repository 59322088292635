import { Box, Button, Center, Input } from "@chakra-ui/react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

import { login } from "../../../../repository/auth/login";
import { useAuth } from "../../../../state/AuthContext";
import { AppRoutes } from "../../../../navigation/AppRoutes";
import { useState } from "react";

const LoginForm = () => {
  const { logIn } = useAuth();
  const navigate = useNavigate();
  const [isInvalid, setIsInvalid] = useState(false);

  const loginHandler = useMutation(login, {
    onSettled: async (data) => {
      if (data?.ok && data.data?.access_token) {
        await logIn(data.data);
        const nextRoute = AppRoutes.Dashboard.path;
        navigate(nextRoute);
      } else if (!data?.ok) {
        setIsInvalid(true);
      }
    },
  });

  const onSubmit = (values: Record<string, string>) => {
    const credentials = {
      password: values.password,
    };
    loginHandler.mutate(credentials);
  };

  return (
    <Center>
      <Box maxWidth="md" margin="0 auto">
        <form onSubmit={(e) => {
          e.preventDefault();
          onSubmit({
            password: (e.target as any).password.value,
          });
        }}>
          <Input onInput={() => setIsInvalid(false)} id="password" isInvalid={isInvalid} placeholder={"Введите пароль"} type="password" />
          <Box p={2.5} />
          <Button
            name={"Login"}
            isFullWidth
            type="submit"
            isLoading={loginHandler.isLoading}
          >Войти</Button>
        </form>
      </Box>
    </Center>
  );
};

export default LoginForm;
