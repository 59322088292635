import { QueryClient, QueryClientProvider } from 'react-query';
import AuthContextProvider from 'state/AuthContext';
import UsersContextProvider from 'state/UsersContext';

import ApplicationRouter from './navigation/ApplicationRouter';
import ModalsContextProvider from './state/ModalContext';

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthContextProvider>
        <ModalsContextProvider>
          <UsersContextProvider>
            <ApplicationRouter />
          </UsersContextProvider>
        </ModalsContextProvider>
      </AuthContextProvider>
    </QueryClientProvider>
  );
}
