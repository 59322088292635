import { QueryRenderer, QueryRendererRenderProps } from '@cubejs-client/react';
import { ResultSet, Query } from '@cubejs-client/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Flex, FlexProps, useColorModeValue, Text, Skeleton } from '@chakra-ui/react';
import { DragHandleIcon } from '@chakra-ui/icons';
import { useCube } from 'hooks/useCube';
import { useAuth } from 'state/AuthContext';

export interface PivotConfig {
  x: string[];
  y: string[];
  fillMissingDates?: boolean;
  joinDateRange?: boolean;
}

export interface ChartRendererProps extends FlexProps {
  renderOnlyElement?: boolean;
  isDraggable?: boolean;
  chartProps: {
    id: string;
    query: Query | Query[];
    pivotConfig: PivotConfig;
    element: (props: { resultSet: ResultSet; pivotConfig: PivotConfig }) => JSX.Element;
    title: string;
    secondary?: JSX.Element;
  };
}

export const ChartRenderer = ({ chartProps, ...rest }: ChartRendererProps) => {
  const { logOut } = useAuth();
  const cardBg = useColorModeValue('white', 'navy.800');

  const renderChart = ({
    resultSet,
    error,
    pivotConfig,
    RendererElement,
    isDraggable
  }: QueryRendererRenderProps & {
    pivotConfig: PivotConfig;
    isDraggable?: boolean;
    RendererElement: (props: { resultSet: ResultSet; pivotConfig: PivotConfig }) => JSX.Element;
  }) => {
    if (error) {
      if (error.toString() === 'Error: Invalid token') {
        logOut();
      }

      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return (
        <Skeleton
          width={'100%'}
          height={'100%'}
          speed={2}
          opacity={0.9}
          borderRadius={'8px'}
          boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
          {...rest}
        />
      );
    }

    return (
      <Flex
        flexDirection={'column'}
        p={rest.renderOnlyElement ? undefined : '20px'}
        width={'100%'}
        height={'100%'}
        borderRadius={'8px'}
        boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
        gap={4}>
        <Flex flexDirection={'row'} display={rest.renderOnlyElement ? 'none' : undefined}>
          <Text
            my={'auto'}
            display={'block'}
            width={'100%'}
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            _active={{
              bg: 'inherit',
              transform: 'none',
              borderColor: 'transparent'
            }}
            _focus={{
              boxShadow: 'none'
            }}>
            {chartProps.title}
          </Text>
          {isDraggable === false ? null : (
            <DragHandleIcon
              my={'auto'}
              {...listeners}
              {...attributes}
              _focus={{
                bg: 'inherit',
                transform: 'none',
                border: 'none',
                outline: 'none'
              }}
            />
          )}
        </Flex>
        <RendererElement resultSet={resultSet} pivotConfig={pivotConfig} />
      </Flex>
    );
  };

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: chartProps.id
  });
  const cube = useCube();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  };

  return (
    <Flex
      ref={setNodeRef}
      bg={cardBg}
      style={style}
      {...attributes}
      {...listeners}
      borderRadius={'8px'}
      boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
      {...rest}>
      <QueryRenderer
        query={chartProps.query}
        cubejsApi={cube}
        resetResultSetOnChange={false}
        updateOnlyOnStateChange={true}
        render={(props) =>
          renderChart({
            ...props,
            isDraggable: rest.isDraggable,
            pivotConfig: chartProps.pivotConfig,
            RendererElement: chartProps.element
          })
        }
      />
    </Flex>
  );
};
