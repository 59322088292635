export const COUNTRIES = {
  "AF": {
      "country": "Afghanistan",
      "alpha2Code": "AF",
      "alpha3Code": "AFG",
      "numericCode": 4,
      "lat": 33,
      "lon": 65,
      "emojiFlag": "🇦🇫"
  },
  "AL": {
      "country": "Albania",
      "alpha2Code": "AL",
      "alpha3Code": "ALB",
      "numericCode": 8,
      "lat": 41,
      "lon": 20,
      "emojiFlag": "🇦🇱"
  },
  "DZ": {
      "country": "Algeria",
      "alpha2Code": "DZ",
      "alpha3Code": "DZA",
      "numericCode": 12,
      "lat": 28,
      "lon": 3,
      "emojiFlag": "🇩🇿"
  },
  "AS": {
      "country": "American Samoa",
      "alpha2Code": "AS",
      "alpha3Code": "ASM",
      "numericCode": 16,
      "lat": -14.3333,
      "lon": -170,
      "emojiFlag": "🇦🇸"
  },
  "AD": {
      "country": "Andorra",
      "alpha2Code": "AD",
      "alpha3Code": "AND",
      "numericCode": 20,
      "lat": 42.5,
      "lon": 1.6,
      "emojiFlag": "🇦🇩"
  },
  "AO": {
      "country": "Angola",
      "alpha2Code": "AO",
      "alpha3Code": "AGO",
      "numericCode": 24,
      "lat": -12.5,
      "lon": 18.5,
      "emojiFlag": "🇦🇴"
  },
  "AI": {
      "country": "Anguilla",
      "alpha2Code": "AI",
      "alpha3Code": "AIA",
      "numericCode": 660,
      "lat": 18.25,
      "lon": -63.1667,
      "emojiFlag": "🇦🇮"
  },
  "AQ": {
      "country": "Antarctica",
      "alpha2Code": "AQ",
      "alpha3Code": "ATA",
      "numericCode": 10,
      "lat": -90,
      "lon": 0,
      "emojiFlag": "🇦🇶"
  },
  "AG": {
      "country": "Antigua and Barbuda",
      "alpha2Code": "AG",
      "alpha3Code": "ATG",
      "numericCode": 28,
      "lat": 17.05,
      "lon": -61.8,
      "emojiFlag": "🇦🇬"
  },
  "AR": {
      "country": "Argentina",
      "alpha2Code": "AR",
      "alpha3Code": "ARG",
      "numericCode": 32,
      "lat": -34,
      "lon": -64,
      "emojiFlag": "🇦🇷"
  },
  "AM": {
      "country": "Armenia",
      "alpha2Code": "AM",
      "alpha3Code": "ARM",
      "numericCode": 51,
      "lat": 40,
      "lon": 45,
      "emojiFlag": "🇦🇲"
  },
  "AW": {
      "country": "Aruba",
      "alpha2Code": "AW",
      "alpha3Code": "ABW",
      "numericCode": 533,
      "lat": 12.5,
      "lon": -69.9667,
      "emojiFlag": "🇦🇼"
  },
  "AU": {
      "country": "Australia",
      "alpha2Code": "AU",
      "alpha3Code": "AUS",
      "numericCode": 36,
      "lat": -27,
      "lon": 133,
      "emojiFlag": "🇦🇺"
  },
  "AT": {
      "country": "Austria",
      "alpha2Code": "AT",
      "alpha3Code": "AUT",
      "numericCode": 40,
      "lat": 47.3333,
      "lon": 13.3333,
      "emojiFlag": "🇦🇹"
  },
  "AZ": {
      "country": "Azerbaijan",
      "alpha2Code": "AZ",
      "alpha3Code": "AZE",
      "numericCode": 31,
      "lat": 40.5,
      "lon": 47.5,
      "emojiFlag": "🇦🇿"
  },
  "BS": {
      "country": "Bahamas",
      "alpha2Code": "BS",
      "alpha3Code": "BHS",
      "numericCode": 44,
      "lat": 24.25,
      "lon": -76,
      "emojiFlag": "🇧🇸"
  },
  "BH": {
      "country": "Bahrain",
      "alpha2Code": "BH",
      "alpha3Code": "BHR",
      "numericCode": 48,
      "lat": 26,
      "lon": 50.55,
      "emojiFlag": "🇧🇭"
  },
  "BD": {
      "country": "Bangladesh",
      "alpha2Code": "BD",
      "alpha3Code": "BGD",
      "numericCode": 50,
      "lat": 24,
      "lon": 90,
      "emojiFlag": "🇧🇩"
  },
  "BB": {
      "country": "Barbados",
      "alpha2Code": "BB",
      "alpha3Code": "BRB",
      "numericCode": 52,
      "lat": 13.1667,
      "lon": -59.5333,
      "emojiFlag": "🇧🇧"
  },
  "BY": {
      "country": "Belarus",
      "alpha2Code": "BY",
      "alpha3Code": "BLR",
      "numericCode": 112,
      "lat": 53,
      "lon": 28,
      "emojiFlag": "🇧🇾"
  },
  "BE": {
      "country": "Belgium",
      "alpha2Code": "BE",
      "alpha3Code": "BEL",
      "numericCode": 56,
      "lat": 50.8333,
      "lon": 4,
      "emojiFlag": "🇧🇪"
  },
  "BZ": {
      "country": "Belize",
      "alpha2Code": "BZ",
      "alpha3Code": "BLZ",
      "numericCode": 84,
      "lat": 17.25,
      "lon": -88.75,
      "emojiFlag": "🇧🇿"
  },
  "BJ": {
      "country": "Benin",
      "alpha2Code": "BJ",
      "alpha3Code": "BEN",
      "numericCode": 204,
      "lat": 9.5,
      "lon": 2.25,
      "emojiFlag": "🇧🇯"
  },
  "BM": {
      "country": "Bermuda",
      "alpha2Code": "BM",
      "alpha3Code": "BMU",
      "numericCode": 60,
      "lat": 32.3333,
      "lon": -64.75,
      "emojiFlag": "🇧🇲"
  },
  "BT": {
      "country": "Bhutan",
      "alpha2Code": "BT",
      "alpha3Code": "BTN",
      "numericCode": 64,
      "lat": 27.5,
      "lon": 90.5,
      "emojiFlag": "🇧🇹"
  },
  "BO": {
      "country": "Bolivia",
      "alpha2Code": "BO",
      "alpha3Code": "BOL",
      "numericCode": 68,
      "lat": -17,
      "lon": -65,
      "emojiFlag": "🇧🇴"
  },
  "BA": {
      "country": "Bosnia and Herzegovina",
      "alpha2Code": "BA",
      "alpha3Code": "BIH",
      "numericCode": 70,
      "lat": 44,
      "lon": 18,
      "emojiFlag": "🇧🇦"
  },
  "BW": {
      "country": "Botswana",
      "alpha2Code": "BW",
      "alpha3Code": "BWA",
      "numericCode": 72,
      "lat": -22,
      "lon": 24,
      "emojiFlag": "🇧🇼"
  },
  "BV": {
      "country": "Bouvet Island",
      "alpha2Code": "BV",
      "alpha3Code": "BVT",
      "numericCode": 74,
      "lat": -54.4333,
      "lon": 3.4,
      "emojiFlag": "🇧🇻"
  },
  "BR": {
      "country": "Brazil",
      "alpha2Code": "BR",
      "alpha3Code": "BRA",
      "numericCode": 76,
      "lat": -10,
      "lon": -55,
      "emojiFlag": "🇧🇷"
  },
  "IO": {
      "country": "British Indian Ocean Territory",
      "alpha2Code": "IO",
      "alpha3Code": "IOT",
      "numericCode": 86,
      "lat": -6,
      "lon": 71.5,
      "emojiFlag": "🇮🇴"
  },
  "BN": {
      "country": "Brunei",
      "alpha2Code": "BN",
      "alpha3Code": "BRN",
      "numericCode": 96,
      "lat": 4.5,
      "lon": 114.6667,
      "emojiFlag": "🇧🇳"
  },
  "BG": {
      "country": "Bulgaria",
      "alpha2Code": "BG",
      "alpha3Code": "BGR",
      "numericCode": 100,
      "lat": 43,
      "lon": 25,
      "emojiFlag": "🇧🇬"
  },
  "BF": {
      "country": "Burkina Faso",
      "alpha2Code": "BF",
      "alpha3Code": "BFA",
      "numericCode": 854,
      "lat": 13,
      "lon": -2,
      "emojiFlag": "🇧🇫"
  },
  "BI": {
      "country": "Burundi",
      "alpha2Code": "BI",
      "alpha3Code": "BDI",
      "numericCode": 108,
      "lat": -3.5,
      "lon": 30,
      "emojiFlag": "🇧🇮"
  },
  "KH": {
      "country": "Cambodia",
      "alpha2Code": "KH",
      "alpha3Code": "KHM",
      "numericCode": 116,
      "lat": 13,
      "lon": 105,
      "emojiFlag": "🇰🇭"
  },
  "CM": {
      "country": "Cameroon",
      "alpha2Code": "CM",
      "alpha3Code": "CMR",
      "numericCode": 120,
      "lat": 6,
      "lon": 12,
      "emojiFlag": "🇨🇲"
  },
  "CA": {
      "country": "Canada",
      "alpha2Code": "CA",
      "alpha3Code": "CAN",
      "numericCode": 124,
      "lat": 60,
      "lon": -95,
      "emojiFlag": "🇨🇦"
  },
  "CV": {
      "country": "Cape Verde",
      "alpha2Code": "CV",
      "alpha3Code": "CPV",
      "numericCode": 132,
      "lat": 16,
      "lon": -24,
      "emojiFlag": "🇨🇻"
  },
  "KY": {
      "country": "Cayman Islands",
      "alpha2Code": "KY",
      "alpha3Code": "CYM",
      "numericCode": 136,
      "lat": 19.5,
      "lon": -80.5,
      "emojiFlag": "🇰🇾"
  },
  "CF": {
      "country": "Central African Republic",
      "alpha2Code": "CF",
      "alpha3Code": "CAF",
      "numericCode": 140,
      "lat": 7,
      "lon": 21,
      "emojiFlag": "🇨🇫"
  },
  "TD": {
      "country": "Chad",
      "alpha2Code": "TD",
      "alpha3Code": "TCD",
      "numericCode": 148,
      "lat": 15,
      "lon": 19,
      "emojiFlag": "🇹🇩"
  },
  "CL": {
      "country": "Chile",
      "alpha2Code": "CL",
      "alpha3Code": "CHL",
      "numericCode": 152,
      "lat": -30,
      "lon": -71,
      "emojiFlag": "🇨🇱"
  },
  "CN": {
      "country": "China",
      "alpha2Code": "CN",
      "alpha3Code": "CHN",
      "numericCode": 156,
      "lat": 35,
      "lon": 105,
      "emojiFlag": "🇨🇳"
  },
  "CX": {
      "country": "Christmas Island",
      "alpha2Code": "CX",
      "alpha3Code": "CXR",
      "numericCode": 162,
      "lat": -10.5,
      "lon": 105.6667,
      "emojiFlag": "🇨🇽"
  },
  "CC": {
      "country": "Cocos (Keeling) Islands",
      "alpha2Code": "CC",
      "alpha3Code": "CCK",
      "numericCode": 166,
      "lat": -12.5,
      "lon": 96.8333,
      "emojiFlag": "🇨🇨"
  },
  "CO": {
      "country": "Colombia",
      "alpha2Code": "CO",
      "alpha3Code": "COL",
      "numericCode": 170,
      "lat": 4,
      "lon": -72,
      "emojiFlag": "🇨🇴"
  },
  "KM": {
      "country": "Comoros",
      "alpha2Code": "KM",
      "alpha3Code": "COM",
      "numericCode": 174,
      "lat": -12.1667,
      "lon": 44.25,
      "emojiFlag": "🇰🇲"
  },
  "CG": {
      "country": "Congo",
      "alpha2Code": "CG",
      "alpha3Code": "COG",
      "numericCode": 178,
      "lat": -1,
      "lon": 15,
      "emojiFlag": "🇨🇬"
  },
  "CD": {
      "country": "Congo, the Democratic Republic of the",
      "alpha2Code": "CD",
      "alpha3Code": "COD",
      "numericCode": 180,
      "lat": 0,
      "lon": 25,
      "emojiFlag": "🇨🇩"
  },
  "CK": {
      "country": "Cook Islands",
      "alpha2Code": "CK",
      "alpha3Code": "COK",
      "numericCode": 184,
      "lat": -21.2333,
      "lon": -159.7667,
      "emojiFlag": "🇨🇰"
  },
  "CR": {
      "country": "Costa Rica",
      "alpha2Code": "CR",
      "alpha3Code": "CRI",
      "numericCode": 188,
      "lat": 10,
      "lon": -84,
      "emojiFlag": "🇨🇷"
  },
  "CI": {
      "country": "Ivory Coast",
      "alpha2Code": "CI",
      "alpha3Code": "CIV",
      "numericCode": 384,
      "lat": 8,
      "lon": -5,
      "emojiFlag": "🇨🇮"
  },
  "HR": {
      "country": "Croatia",
      "alpha2Code": "HR",
      "alpha3Code": "HRV",
      "numericCode": 191,
      "lat": 45.1667,
      "lon": 15.5,
      "emojiFlag": "🇭🇷"
  },
  "CU": {
      "country": "Cuba",
      "alpha2Code": "CU",
      "alpha3Code": "CUB",
      "numericCode": 192,
      "lat": 21.5,
      "lon": -80,
      "emojiFlag": "🇨🇺"
  },
  "CY": {
      "country": "Cyprus",
      "alpha2Code": "CY",
      "alpha3Code": "CYP",
      "numericCode": 196,
      "lat": 35,
      "lon": 33,
      "emojiFlag": "🇨🇾"
  },
  "CZ": {
      "country": "Czech Republic",
      "alpha2Code": "CZ",
      "alpha3Code": "CZE",
      "numericCode": 203,
      "lat": 49.75,
      "lon": 15.5,
      "emojiFlag": "🇨🇿"
  },
  "DK": {
      "country": "Denmark",
      "alpha2Code": "DK",
      "alpha3Code": "DNK",
      "numericCode": 208,
      "lat": 56,
      "lon": 10,
      "emojiFlag": "🇩🇰"
  },
  "DJ": {
      "country": "Djibouti",
      "alpha2Code": "DJ",
      "alpha3Code": "DJI",
      "numericCode": 262,
      "lat": 11.5,
      "lon": 43,
      "emojiFlag": "🇩🇯"
  },
  "DM": {
      "country": "Dominica",
      "alpha2Code": "DM",
      "alpha3Code": "DMA",
      "numericCode": 212,
      "lat": 15.4167,
      "lon": -61.3333,
      "emojiFlag": "🇩🇲"
  },
  "DO": {
      "country": "Dominican Republic",
      "alpha2Code": "DO",
      "alpha3Code": "DOM",
      "numericCode": 214,
      "lat": 19,
      "lon": -70.6667,
      "emojiFlag": "🇩🇴"
  },
  "EC": {
      "country": "Ecuador",
      "alpha2Code": "EC",
      "alpha3Code": "ECU",
      "numericCode": 218,
      "lat": -2,
      "lon": -77.5,
      "emojiFlag": "🇪🇨"
  },
  "EG": {
      "country": "Egypt",
      "alpha2Code": "EG",
      "alpha3Code": "EGY",
      "numericCode": 818,
      "lat": 27,
      "lon": 30,
      "emojiFlag": "🇪🇬"
  },
  "SV": {
      "country": "El Salvador",
      "alpha2Code": "SV",
      "alpha3Code": "SLV",
      "numericCode": 222,
      "lat": 13.8333,
      "lon": -88.9167,
      "emojiFlag": "🇸🇻"
  },
  "GQ": {
      "country": "Equatorial Guinea",
      "alpha2Code": "GQ",
      "alpha3Code": "GNQ",
      "numericCode": 226,
      "lat": 2,
      "lon": 10,
      "emojiFlag": "🇬🇶"
  },
  "ER": {
      "country": "Eritrea",
      "alpha2Code": "ER",
      "alpha3Code": "ERI",
      "numericCode": 232,
      "lat": 15,
      "lon": 39,
      "emojiFlag": "🇪🇷"
  },
  "EE": {
      "country": "Estonia",
      "alpha2Code": "EE",
      "alpha3Code": "EST",
      "numericCode": 233,
      "lat": 59,
      "lon": 26,
      "emojiFlag": "🇪🇪"
  },
  "ET": {
      "country": "Ethiopia",
      "alpha2Code": "ET",
      "alpha3Code": "ETH",
      "numericCode": 231,
      "lat": 8,
      "lon": 38,
      "emojiFlag": "🇪🇹"
  },
  "FK": {
      "country": "Falkland Islands (Malvinas)",
      "alpha2Code": "FK",
      "alpha3Code": "FLK",
      "numericCode": 238,
      "lat": -51.75,
      "lon": -59,
      "emojiFlag": "🇫🇰"
  },
  "FO": {
      "country": "Faroe Islands",
      "alpha2Code": "FO",
      "alpha3Code": "FRO",
      "numericCode": 234,
      "lat": 62,
      "lon": -7,
      "emojiFlag": "🇫🇴"
  },
  "FJ": {
      "country": "Fiji",
      "alpha2Code": "FJ",
      "alpha3Code": "FJI",
      "numericCode": 242,
      "lat": -18,
      "lon": 175,
      "emojiFlag": "🇫🇯"
  },
  "FI": {
      "country": "Finland",
      "alpha2Code": "FI",
      "alpha3Code": "FIN",
      "numericCode": 246,
      "lat": 64,
      "lon": 26,
      "emojiFlag": "🇫🇮"
  },
  "FR": {
      "country": "France",
      "alpha2Code": "FR",
      "alpha3Code": "FRA",
      "numericCode": 250,
      "lat": 46,
      "lon": 2,
      "emojiFlag": "🇫🇷"
  },
  "GF": {
      "country": "French Guiana",
      "alpha2Code": "GF",
      "alpha3Code": "GUF",
      "numericCode": 254,
      "lat": 4,
      "lon": -53,
      "emojiFlag": "🇬🇫"
  },
  "PF": {
      "country": "French Polynesia",
      "alpha2Code": "PF",
      "alpha3Code": "PYF",
      "numericCode": 258,
      "lat": -15,
      "lon": -140,
      "emojiFlag": "🇵🇫"
  },
  "TF": {
      "country": "French Southern Territories",
      "alpha2Code": "TF",
      "alpha3Code": "ATF",
      "numericCode": 260,
      "lat": -43,
      "lon": 67,
      "emojiFlag": "🇹🇫"
  },
  "GA": {
      "country": "Gabon",
      "alpha2Code": "GA",
      "alpha3Code": "GAB",
      "numericCode": 266,
      "lat": -1,
      "lon": 11.75,
      "emojiFlag": "🇬🇦"
  },
  "GM": {
      "country": "Gambia",
      "alpha2Code": "GM",
      "alpha3Code": "GMB",
      "numericCode": 270,
      "lat": 13.4667,
      "lon": -16.5667,
      "emojiFlag": "🇬🇲"
  },
  "GE": {
      "country": "Georgia",
      "alpha2Code": "GE",
      "alpha3Code": "GEO",
      "numericCode": 268,
      "lat": 42,
      "lon": 43.5,
      "emojiFlag": "🇬🇪"
  },
  "DE": {
      "country": "Germany",
      "alpha2Code": "DE",
      "alpha3Code": "DEU",
      "numericCode": 276,
      "lat": 51,
      "lon": 9,
      "emojiFlag": "🇩🇪"
  },
  "GH": {
      "country": "Ghana",
      "alpha2Code": "GH",
      "alpha3Code": "GHA",
      "numericCode": 288,
      "lat": 8,
      "lon": -2,
      "emojiFlag": "🇬🇭"
  },
  "GI": {
      "country": "Gibraltar",
      "alpha2Code": "GI",
      "alpha3Code": "GIB",
      "numericCode": 292,
      "lat": 36.1833,
      "lon": -5.3667,
      "emojiFlag": "🇬🇮"
  },
  "GR": {
      "country": "Greece",
      "alpha2Code": "GR",
      "alpha3Code": "GRC",
      "numericCode": 300,
      "lat": 39,
      "lon": 22,
      "emojiFlag": "🇬🇷"
  },
  "GL": {
      "country": "Greenland",
      "alpha2Code": "GL",
      "alpha3Code": "GRL",
      "numericCode": 304,
      "lat": 72,
      "lon": -40,
      "emojiFlag": "🇬🇱"
  },
  "GD": {
      "country": "Grenada",
      "alpha2Code": "GD",
      "alpha3Code": "GRD",
      "numericCode": 308,
      "lat": 12.1167,
      "lon": -61.6667,
      "emojiFlag": "🇬🇩"
  },
  "GP": {
      "country": "Guadeloupe",
      "alpha2Code": "GP",
      "alpha3Code": "GLP",
      "numericCode": 312,
      "lat": 16.25,
      "lon": -61.5833,
      "emojiFlag": "🇬🇵"
  },
  "GU": {
      "country": "Guam",
      "alpha2Code": "GU",
      "alpha3Code": "GUM",
      "numericCode": 316,
      "lat": 13.4667,
      "lon": 144.7833,
      "emojiFlag": "🇬🇺"
  },
  "GT": {
      "country": "Guatemala",
      "alpha2Code": "GT",
      "alpha3Code": "GTM",
      "numericCode": 320,
      "lat": 15.5,
      "lon": -90.25,
      "emojiFlag": "🇬🇹"
  },
  "GG": {
      "country": "Guernsey",
      "alpha2Code": "GG",
      "alpha3Code": "GGY",
      "numericCode": 831,
      "lat": 49.5,
      "lon": -2.56,
      "emojiFlag": "🇬🇬"
  },
  "GN": {
      "country": "Guinea",
      "alpha2Code": "GN",
      "alpha3Code": "GIN",
      "numericCode": 324,
      "lat": 11,
      "lon": -10,
      "emojiFlag": "🇬🇳"
  },
  "GW": {
      "country": "Guinea-Bissau",
      "alpha2Code": "GW",
      "alpha3Code": "GNB",
      "numericCode": 624,
      "lat": 12,
      "lon": -15,
      "emojiFlag": "🇬🇼"
  },
  "GY": {
      "country": "Guyana",
      "alpha2Code": "GY",
      "alpha3Code": "GUY",
      "numericCode": 328,
      "lat": 5,
      "lon": -59,
      "emojiFlag": "🇬🇾"
  },
  "HT": {
      "country": "Haiti",
      "alpha2Code": "HT",
      "alpha3Code": "HTI",
      "numericCode": 332,
      "lat": 19,
      "lon": -72.4167,
      "emojiFlag": "🇭🇹"
  },
  "HM": {
      "country": "Heard Island and McDonald Islands",
      "alpha2Code": "HM",
      "alpha3Code": "HMD",
      "numericCode": 334,
      "lat": -53.1,
      "lon": 72.5167,
      "emojiFlag": "🇭🇲"
  },
  "VA": {
      "country": "Holy See (Vatican City State)",
      "alpha2Code": "VA",
      "alpha3Code": "VAT",
      "numericCode": 336,
      "lat": 41.9,
      "lon": 12.45,
      "emojiFlag": "🇻🇦"
  },
  "HN": {
      "country": "Honduras",
      "alpha2Code": "HN",
      "alpha3Code": "HND",
      "numericCode": 340,
      "lat": 15,
      "lon": -86.5,
      "emojiFlag": "🇭🇳"
  },
  "HK": {
      "country": "Hong Kong",
      "alpha2Code": "HK",
      "alpha3Code": "HKG",
      "numericCode": 344,
      "lat": 22.25,
      "lon": 114.1667,
      "emojiFlag": "🇭🇰"
  },
  "HU": {
      "country": "Hungary",
      "alpha2Code": "HU",
      "alpha3Code": "HUN",
      "numericCode": 348,
      "lat": 47,
      "lon": 20,
      "emojiFlag": "🇭🇺"
  },
  "IS": {
      "country": "Iceland",
      "alpha2Code": "IS",
      "alpha3Code": "ISL",
      "numericCode": 352,
      "lat": 65,
      "lon": -18,
      "emojiFlag": "🇮🇸"
  },
  "IN": {
      "country": "India",
      "alpha2Code": "IN",
      "alpha3Code": "IND",
      "numericCode": 356,
      "lat": 20,
      "lon": 77,
      "emojiFlag": "🇮🇳"
  },
  "ID": {
      "country": "Indonesia",
      "alpha2Code": "ID",
      "alpha3Code": "IDN",
      "numericCode": 360,
      "lat": -5,
      "lon": 120,
      "emojiFlag": "🇮🇩"
  },
  "IR": {
      "country": "Iran, Islamic Republic of",
      "alpha2Code": "IR",
      "alpha3Code": "IRN",
      "numericCode": 364,
      "lat": 32,
      "lon": 53,
      "emojiFlag": "🇮🇷"
  },
  "IQ": {
      "country": "Iraq",
      "alpha2Code": "IQ",
      "alpha3Code": "IRQ",
      "numericCode": 368,
      "lat": 33,
      "lon": 44,
      "emojiFlag": "🇮🇶"
  },
  "IE": {
      "country": "Ireland",
      "alpha2Code": "IE",
      "alpha3Code": "IRL",
      "numericCode": 372,
      "lat": 53,
      "lon": -8,
      "emojiFlag": "🇮🇪"
  },
  "IM": {
      "country": "Isle of Man",
      "alpha2Code": "IM",
      "alpha3Code": "IMN",
      "numericCode": 833,
      "lat": 54.23,
      "lon": -4.55,
      "emojiFlag": "🇮🇲"
  },
  "IL": {
      "country": "Israel",
      "alpha2Code": "IL",
      "alpha3Code": "ISR",
      "numericCode": 376,
      "lat": 31.5,
      "lon": 34.75,
      "emojiFlag": "🇮🇱"
  },
  "IT": {
      "country": "Italy",
      "alpha2Code": "IT",
      "alpha3Code": "ITA",
      "numericCode": 380,
      "lat": 42.8333,
      "lon": 12.8333,
      "emojiFlag": "🇮🇹"
  },
  "JM": {
      "country": "Jamaica",
      "alpha2Code": "JM",
      "alpha3Code": "JAM",
      "numericCode": 388,
      "lat": 18.25,
      "lon": -77.5,
      "emojiFlag": "🇯🇲"
  },
  "JP": {
      "country": "Japan",
      "alpha2Code": "JP",
      "alpha3Code": "JPN",
      "numericCode": 392,
      "lat": 36,
      "lon": 138,
      "emojiFlag": "🇯🇵"
  },
  "JE": {
      "country": "Jersey",
      "alpha2Code": "JE",
      "alpha3Code": "JEY",
      "numericCode": 832,
      "lat": 49.21,
      "lon": -2.13,
      "emojiFlag": "🇯🇪"
  },
  "JO": {
      "country": "Jordan",
      "alpha2Code": "JO",
      "alpha3Code": "JOR",
      "numericCode": 400,
      "lat": 31,
      "lon": 36,
      "emojiFlag": "🇯🇴"
  },
  "KZ": {
      "country": "Kazakhstan",
      "alpha2Code": "KZ",
      "alpha3Code": "KAZ",
      "numericCode": 398,
      "lat": 48,
      "lon": 68,
      "emojiFlag": "🇰🇿"
  },
  "KE": {
      "country": "Kenya",
      "alpha2Code": "KE",
      "alpha3Code": "KEN",
      "numericCode": 404,
      "lat": 1,
      "lon": 38,
      "emojiFlag": "🇰🇪"
  },
  "KI": {
      "country": "Kiribati",
      "alpha2Code": "KI",
      "alpha3Code": "KIR",
      "numericCode": 296,
      "lat": 1.4167,
      "lon": 173,
      "emojiFlag": "🇰🇮"
  },
  "KP": {
      "country": "Korea, Democratic People's Republic of",
      "alpha2Code": "KP",
      "alpha3Code": "PRK",
      "numericCode": 408,
      "lat": 40,
      "lon": 127,
      "emojiFlag": "🇰🇵"
  },
  "KR": {
      "country": "South Korea",
      "alpha2Code": "KR",
      "alpha3Code": "KOR",
      "numericCode": 410,
      "lat": 37,
      "lon": 127.5,
      "emojiFlag": "🇰🇷"
  },
  "KW": {
      "country": "Kuwait",
      "alpha2Code": "KW",
      "alpha3Code": "KWT",
      "numericCode": 414,
      "lat": 29.3375,
      "lon": 47.6581,
      "emojiFlag": "🇰🇼"
  },
  "KG": {
      "country": "Kyrgyzstan",
      "alpha2Code": "KG",
      "alpha3Code": "KGZ",
      "numericCode": 417,
      "lat": 41,
      "lon": 75,
      "emojiFlag": "🇰🇬"
  },
  "LA": {
      "country": "Lao People's Democratic Republic",
      "alpha2Code": "LA",
      "alpha3Code": "LAO",
      "numericCode": 418,
      "lat": 18,
      "lon": 105,
      "emojiFlag": "🇱🇦"
  },
  "LV": {
      "country": "Latvia",
      "alpha2Code": "LV",
      "alpha3Code": "LVA",
      "numericCode": 428,
      "lat": 57,
      "lon": 25,
      "emojiFlag": "🇱🇻"
  },
  "LB": {
      "country": "Lebanon",
      "alpha2Code": "LB",
      "alpha3Code": "LBN",
      "numericCode": 422,
      "lat": 33.8333,
      "lon": 35.8333,
      "emojiFlag": "🇱🇧"
  },
  "LS": {
      "country": "Lesotho",
      "alpha2Code": "LS",
      "alpha3Code": "LSO",
      "numericCode": 426,
      "lat": -29.5,
      "lon": 28.5,
      "emojiFlag": "🇱🇸"
  },
  "LR": {
      "country": "Liberia",
      "alpha2Code": "LR",
      "alpha3Code": "LBR",
      "numericCode": 430,
      "lat": 6.5,
      "lon": -9.5,
      "emojiFlag": "🇱🇷"
  },
  "LY": {
      "country": "Libya",
      "alpha2Code": "LY",
      "alpha3Code": "LBY",
      "numericCode": 434,
      "lat": 25,
      "lon": 17,
      "emojiFlag": "🇱🇾"
  },
  "LI": {
      "country": "Liechtenstein",
      "alpha2Code": "LI",
      "alpha3Code": "LIE",
      "numericCode": 438,
      "lat": 47.1667,
      "lon": 9.5333,
      "emojiFlag": "🇱🇮"
  },
  "LT": {
      "country": "Lithuania",
      "alpha2Code": "LT",
      "alpha3Code": "LTU",
      "numericCode": 440,
      "lat": 56,
      "lon": 24,
      "emojiFlag": "🇱🇹"
  },
  "LU": {
      "country": "Luxembourg",
      "alpha2Code": "LU",
      "alpha3Code": "LUX",
      "numericCode": 442,
      "lat": 49.75,
      "lon": 6.1667,
      "emojiFlag": "🇱🇺"
  },
  "MO": {
      "country": "Macao",
      "alpha2Code": "MO",
      "alpha3Code": "MAC",
      "numericCode": 446,
      "lat": 22.1667,
      "lon": 113.55,
      "emojiFlag": "🇲🇴"
  },
  "MK": {
      "country": "Macedonia, the former Yugoslav Republic of",
      "alpha2Code": "MK",
      "alpha3Code": "MKD",
      "numericCode": 807,
      "lat": 41.8333,
      "lon": 22,
      "emojiFlag": "🇲🇰"
  },
  "MG": {
      "country": "Madagascar",
      "alpha2Code": "MG",
      "alpha3Code": "MDG",
      "numericCode": 450,
      "lat": -20,
      "lon": 47,
      "emojiFlag": "🇲🇬"
  },
  "MW": {
      "country": "Malawi",
      "alpha2Code": "MW",
      "alpha3Code": "MWI",
      "numericCode": 454,
      "lat": -13.5,
      "lon": 34,
      "emojiFlag": "🇲🇼"
  },
  "MY": {
      "country": "Malaysia",
      "alpha2Code": "MY",
      "alpha3Code": "MYS",
      "numericCode": 458,
      "lat": 2.5,
      "lon": 112.5,
      "emojiFlag": "🇲🇾"
  },
  "MV": {
      "country": "Maldives",
      "alpha2Code": "MV",
      "alpha3Code": "MDV",
      "numericCode": 462,
      "lat": 3.25,
      "lon": 73,
      "emojiFlag": "🇲🇻"
  },
  "ML": {
      "country": "Mali",
      "alpha2Code": "ML",
      "alpha3Code": "MLI",
      "numericCode": 466,
      "lat": 17,
      "lon": -4,
      "emojiFlag": "🇲🇱"
  },
  "MT": {
      "country": "Malta",
      "alpha2Code": "MT",
      "alpha3Code": "MLT",
      "numericCode": 470,
      "lat": 35.8333,
      "lon": 14.5833,
      "emojiFlag": "🇲🇹"
  },
  "MH": {
      "country": "Marshall Islands",
      "alpha2Code": "MH",
      "alpha3Code": "MHL",
      "numericCode": 584,
      "lat": 9,
      "lon": 168,
      "emojiFlag": "🇲🇭"
  },
  "MQ": {
      "country": "Martinique",
      "alpha2Code": "MQ",
      "alpha3Code": "MTQ",
      "numericCode": 474,
      "lat": 14.6667,
      "lon": -61,
      "emojiFlag": "🇲🇶"
  },
  "MR": {
      "country": "Mauritania",
      "alpha2Code": "MR",
      "alpha3Code": "MRT",
      "numericCode": 478,
      "lat": 20,
      "lon": -12,
      "emojiFlag": "🇲🇷"
  },
  "MU": {
      "country": "Mauritius",
      "alpha2Code": "MU",
      "alpha3Code": "MUS",
      "numericCode": 480,
      "lat": -20.2833,
      "lon": 57.55,
      "emojiFlag": "🇲🇺"
  },
  "YT": {
      "country": "Mayotte",
      "alpha2Code": "YT",
      "alpha3Code": "MYT",
      "numericCode": 175,
      "lat": -12.8333,
      "lon": 45.1667,
      "emojiFlag": "🇾🇹"
  },
  "MX": {
      "country": "Mexico",
      "alpha2Code": "MX",
      "alpha3Code": "MEX",
      "numericCode": 484,
      "lat": 23,
      "lon": -102,
      "emojiFlag": "🇲🇽"
  },
  "FM": {
      "country": "Micronesia, Federated States of",
      "alpha2Code": "FM",
      "alpha3Code": "FSM",
      "numericCode": 583,
      "lat": 6.9167,
      "lon": 158.25,
      "emojiFlag": "🇫🇲"
  },
  "MD": {
      "country": "Moldova, Republic of",
      "alpha2Code": "MD",
      "alpha3Code": "MDA",
      "numericCode": 498,
      "lat": 47,
      "lon": 29,
      "emojiFlag": "🇲🇩"
  },
  "MC": {
      "country": "Monaco",
      "alpha2Code": "MC",
      "alpha3Code": "MCO",
      "numericCode": 492,
      "lat": 43.7333,
      "lon": 7.4,
      "emojiFlag": "🇲🇨"
  },
  "MN": {
      "country": "Mongolia",
      "alpha2Code": "MN",
      "alpha3Code": "MNG",
      "numericCode": 496,
      "lat": 46,
      "lon": 105,
      "emojiFlag": "🇲🇳"
  },
  "ME": {
      "country": "Montenegro",
      "alpha2Code": "ME",
      "alpha3Code": "MNE",
      "numericCode": 499,
      "lat": 42,
      "lon": 19,
      "emojiFlag": "🇲🇪"
  },
  "MS": {
      "country": "Montserrat",
      "alpha2Code": "MS",
      "alpha3Code": "MSR",
      "numericCode": 500,
      "lat": 16.75,
      "lon": -62.2,
      "emojiFlag": "🇲🇸"
  },
  "MA": {
      "country": "Morocco",
      "alpha2Code": "MA",
      "alpha3Code": "MAR",
      "numericCode": 504,
      "lat": 32,
      "lon": -5,
      "emojiFlag": "🇲🇦"
  },
  "MZ": {
      "country": "Mozambique",
      "alpha2Code": "MZ",
      "alpha3Code": "MOZ",
      "numericCode": 508,
      "lat": -18.25,
      "lon": 35,
      "emojiFlag": "🇲🇿"
  },
  "MM": {
      "country": "Myanmar",
      "alpha2Code": "MM",
      "alpha3Code": "MMR",
      "numericCode": 104,
      "lat": 22,
      "lon": 98,
      "emojiFlag": "🇲🇲"
  },
  "NA": {
      "country": "Namibia",
      "alpha2Code": "NA",
      "alpha3Code": "NAM",
      "numericCode": 516,
      "lat": -22,
      "lon": 17,
      "emojiFlag": "🇳🇦"
  },
  "NR": {
      "country": "Nauru",
      "alpha2Code": "NR",
      "alpha3Code": "NRU",
      "numericCode": 520,
      "lat": -0.5333,
      "lon": 166.9167,
      "emojiFlag": "🇳🇷"
  },
  "NP": {
      "country": "Nepal",
      "alpha2Code": "NP",
      "alpha3Code": "NPL",
      "numericCode": 524,
      "lat": 28,
      "lon": 84,
      "emojiFlag": "🇳🇵"
  },
  "NL": {
      "country": "Netherlands",
      "alpha2Code": "NL",
      "alpha3Code": "NLD",
      "numericCode": 528,
      "lat": 52.5,
      "lon": 5.75,
      "emojiFlag": "🇳🇱"
  },
  "AN": {
      "country": "Netherlands Antilles",
      "alpha2Code": "AN",
      "alpha3Code": "ANT",
      "numericCode": 530,
      "lat": 12.25,
      "lon": -68.75,
      "emojiFlag": "🇦🇳"
  },
  "NC": {
      "country": "New Caledonia",
      "alpha2Code": "NC",
      "alpha3Code": "NCL",
      "numericCode": 540,
      "lat": -21.5,
      "lon": 165.5,
      "emojiFlag": "🇳🇨"
  },
  "NZ": {
      "country": "New Zealand",
      "alpha2Code": "NZ",
      "alpha3Code": "NZL",
      "numericCode": 554,
      "lat": -41,
      "lon": 174,
      "emojiFlag": "🇳🇿"
  },
  "NI": {
      "country": "Nicaragua",
      "alpha2Code": "NI",
      "alpha3Code": "NIC",
      "numericCode": 558,
      "lat": 13,
      "lon": -85,
      "emojiFlag": "🇳🇮"
  },
  "NE": {
      "country": "Niger",
      "alpha2Code": "NE",
      "alpha3Code": "NER",
      "numericCode": 562,
      "lat": 16,
      "lon": 8,
      "emojiFlag": "🇳🇪"
  },
  "NG": {
      "country": "Nigeria",
      "alpha2Code": "NG",
      "alpha3Code": "NGA",
      "numericCode": 566,
      "lat": 10,
      "lon": 8,
      "emojiFlag": "🇳🇬"
  },
  "NU": {
      "country": "Niue",
      "alpha2Code": "NU",
      "alpha3Code": "NIU",
      "numericCode": 570,
      "lat": -19.0333,
      "lon": -169.8667,
      "emojiFlag": "🇳🇺"
  },
  "NF": {
      "country": "Norfolk Island",
      "alpha2Code": "NF",
      "alpha3Code": "NFK",
      "numericCode": 574,
      "lat": -29.0333,
      "lon": 167.95,
      "emojiFlag": "🇳🇫"
  },
  "MP": {
      "country": "Northern Mariana Islands",
      "alpha2Code": "MP",
      "alpha3Code": "MNP",
      "numericCode": 580,
      "lat": 15.2,
      "lon": 145.75,
      "emojiFlag": "🇲🇵"
  },
  "NO": {
      "country": "Norway",
      "alpha2Code": "NO",
      "alpha3Code": "NOR",
      "numericCode": 578,
      "lat": 62,
      "lon": 10,
      "emojiFlag": "🇳🇴"
  },
  "OM": {
      "country": "Oman",
      "alpha2Code": "OM",
      "alpha3Code": "OMN",
      "numericCode": 512,
      "lat": 21,
      "lon": 57,
      "emojiFlag": "🇴🇲"
  },
  "PK": {
      "country": "Pakistan",
      "alpha2Code": "PK",
      "alpha3Code": "PAK",
      "numericCode": 586,
      "lat": 30,
      "lon": 70,
      "emojiFlag": "🇵🇰"
  },
  "PW": {
      "country": "Palau",
      "alpha2Code": "PW",
      "alpha3Code": "PLW",
      "numericCode": 585,
      "lat": 7.5,
      "lon": 134.5,
      "emojiFlag": "🇵🇼"
  },
  "PS": {
      "country": "Palestinian Territory, Occupied",
      "alpha2Code": "PS",
      "alpha3Code": "PSE",
      "numericCode": 275,
      "lat": 32,
      "lon": 35.25,
      "emojiFlag": "🇵🇸"
  },
  "PA": {
      "country": "Panama",
      "alpha2Code": "PA",
      "alpha3Code": "PAN",
      "numericCode": 591,
      "lat": 9,
      "lon": -80,
      "emojiFlag": "🇵🇦"
  },
  "PG": {
      "country": "Papua New Guinea",
      "alpha2Code": "PG",
      "alpha3Code": "PNG",
      "numericCode": 598,
      "lat": -6,
      "lon": 147,
      "emojiFlag": "🇵🇬"
  },
  "PY": {
      "country": "Paraguay",
      "alpha2Code": "PY",
      "alpha3Code": "PRY",
      "numericCode": 600,
      "lat": -23,
      "lon": -58,
      "emojiFlag": "🇵🇾"
  },
  "PE": {
      "country": "Peru",
      "alpha2Code": "PE",
      "alpha3Code": "PER",
      "numericCode": 604,
      "lat": -10,
      "lon": -76,
      "emojiFlag": "🇵🇪"
  },
  "PH": {
      "country": "Philippines",
      "alpha2Code": "PH",
      "alpha3Code": "PHL",
      "numericCode": 608,
      "lat": 13,
      "lon": 122,
      "emojiFlag": "🇵🇭"
  },
  "PN": {
      "country": "Pitcairn",
      "alpha2Code": "PN",
      "alpha3Code": "PCN",
      "numericCode": 612,
      "lat": -24.7,
      "lon": -127.4,
      "emojiFlag": "🇵🇳"
  },
  "PL": {
      "country": "Poland",
      "alpha2Code": "PL",
      "alpha3Code": "POL",
      "numericCode": 616,
      "lat": 52,
      "lon": 20,
      "emojiFlag": "🇵🇱"
  },
  "PT": {
      "country": "Portugal",
      "alpha2Code": "PT",
      "alpha3Code": "PRT",
      "numericCode": 620,
      "lat": 39.5,
      "lon": -8,
      "emojiFlag": "🇵🇹"
  },
  "PR": {
      "country": "Puerto Rico",
      "alpha2Code": "PR",
      "alpha3Code": "PRI",
      "numericCode": 630,
      "lat": 18.25,
      "lon": -66.5,
      "emojiFlag": "🇵🇷"
  },
  "QA": {
      "country": "Qatar",
      "alpha2Code": "QA",
      "alpha3Code": "QAT",
      "numericCode": 634,
      "lat": 25.5,
      "lon": 51.25,
      "emojiFlag": "🇶🇦"
  },
  "RE": {
      "country": "Réunion",
      "alpha2Code": "RE",
      "alpha3Code": "REU",
      "numericCode": 638,
      "lat": -21.1,
      "lon": 55.6,
      "emojiFlag": "🇷🇪"
  },
  "RO": {
      "country": "Romania",
      "alpha2Code": "RO",
      "alpha3Code": "ROU",
      "numericCode": 642,
      "lat": 46,
      "lon": 25,
      "emojiFlag": "🇷🇴"
  },
  "RU": {
      "country": "Russia",
      "alpha2Code": "RU",
      "alpha3Code": "RUS",
      "numericCode": 643,
      "lat": 60,
      "lon": 100,
      "emojiFlag": "🇷🇺"
  },
  "RW": {
      "country": "Rwanda",
      "alpha2Code": "RW",
      "alpha3Code": "RWA",
      "numericCode": 646,
      "lat": -2,
      "lon": 30,
      "emojiFlag": "🇷🇼"
  },
  "SH": {
      "country": "Saint Helena, Ascension and Tristan da Cunha",
      "alpha2Code": "SH",
      "alpha3Code": "SHN",
      "numericCode": 654,
      "lat": -15.9333,
      "lon": -5.7,
      "emojiFlag": "🇸🇭"
  },
  "KN": {
      "country": "Saint Kitts and Nevis",
      "alpha2Code": "KN",
      "alpha3Code": "KNA",
      "numericCode": 659,
      "lat": 17.3333,
      "lon": -62.75,
      "emojiFlag": "🇰🇳"
  },
  "LC": {
      "country": "Saint Lucia",
      "alpha2Code": "LC",
      "alpha3Code": "LCA",
      "numericCode": 662,
      "lat": 13.8833,
      "lon": -61.1333,
      "emojiFlag": "🇱🇨"
  },
  "PM": {
      "country": "Saint Pierre and Miquelon",
      "alpha2Code": "PM",
      "alpha3Code": "SPM",
      "numericCode": 666,
      "lat": 46.8333,
      "lon": -56.3333,
      "emojiFlag": "🇵🇲"
  },
  "VC": {
      "country": "Saint Vincent and the Grenadines",
      "alpha2Code": "VC",
      "alpha3Code": "VCT",
      "numericCode": 670,
      "lat": 13.25,
      "lon": -61.2,
      "emojiFlag": "🇻🇨"
  },
  "WS": {
      "country": "Samoa",
      "alpha2Code": "WS",
      "alpha3Code": "WSM",
      "numericCode": 882,
      "lat": -13.5833,
      "lon": -172.3333,
      "emojiFlag": "🇼🇸"
  },
  "SM": {
      "country": "San Marino",
      "alpha2Code": "SM",
      "alpha3Code": "SMR",
      "numericCode": 674,
      "lat": 43.7667,
      "lon": 12.4167,
      "emojiFlag": "🇸🇲"
  },
  "ST": {
      "country": "Sao Tome and Principe",
      "alpha2Code": "ST",
      "alpha3Code": "STP",
      "numericCode": 678,
      "lat": 1,
      "lon": 7,
      "emojiFlag": "🇸🇹"
  },
  "SA": {
      "country": "Saudi Arabia",
      "alpha2Code": "SA",
      "alpha3Code": "SAU",
      "numericCode": 682,
      "lat": 25,
      "lon": 45,
      "emojiFlag": "🇸🇦"
  },
  "SN": {
      "country": "Senegal",
      "alpha2Code": "SN",
      "alpha3Code": "SEN",
      "numericCode": 686,
      "lat": 14,
      "lon": -14,
      "emojiFlag": "🇸🇳"
  },
  "RS": {
      "country": "Serbia",
      "alpha2Code": "RS",
      "alpha3Code": "SRB",
      "numericCode": 688,
      "lat": 44,
      "lon": 21,
      "emojiFlag": "🇷🇸"
  },
  "SC": {
      "country": "Seychelles",
      "alpha2Code": "SC",
      "alpha3Code": "SYC",
      "numericCode": 690,
      "lat": -4.5833,
      "lon": 55.6667,
      "emojiFlag": "🇸🇨"
  },
  "SL": {
      "country": "Sierra Leone",
      "alpha2Code": "SL",
      "alpha3Code": "SLE",
      "numericCode": 694,
      "lat": 8.5,
      "lon": -11.5,
      "emojiFlag": "🇸🇱"
  },
  "SG": {
      "country": "Singapore",
      "alpha2Code": "SG",
      "alpha3Code": "SGP",
      "numericCode": 702,
      "lat": 1.3667,
      "lon": 103.8,
      "emojiFlag": "🇸🇬"
  },
  "SK": {
      "country": "Slovakia",
      "alpha2Code": "SK",
      "alpha3Code": "SVK",
      "numericCode": 703,
      "lat": 48.6667,
      "lon": 19.5,
      "emojiFlag": "🇸🇰"
  },
  "SI": {
      "country": "Slovenia",
      "alpha2Code": "SI",
      "alpha3Code": "SVN",
      "numericCode": 705,
      "lat": 46,
      "lon": 15,
      "emojiFlag": "🇸🇮"
  },
  "SB": {
      "country": "Solomon Islands",
      "alpha2Code": "SB",
      "alpha3Code": "SLB",
      "numericCode": 90,
      "lat": -8,
      "lon": 159,
      "emojiFlag": "🇸🇧"
  },
  "SO": {
      "country": "Somalia",
      "alpha2Code": "SO",
      "alpha3Code": "SOM",
      "numericCode": 706,
      "lat": 10,
      "lon": 49,
      "emojiFlag": "🇸🇴"
  },
  "ZA": {
      "country": "South Africa",
      "alpha2Code": "ZA",
      "alpha3Code": "ZAF",
      "numericCode": 710,
      "lat": -29,
      "lon": 24,
      "emojiFlag": "🇿🇦"
  },
  "GS": {
      "country": "South Georgia and the South Sandwich Islands",
      "alpha2Code": "GS",
      "alpha3Code": "SGS",
      "numericCode": 239,
      "lat": -54.5,
      "lon": -37,
      "emojiFlag": "🇬🇸"
  },
  "SS": {
      "country": "South Sudan",
      "alpha2Code": "SS",
      "alpha3Code": "SSD",
      "numericCode": 728,
      "lat": 8,
      "lon": 30,
      "emojiFlag": "🇸🇸"
  },
  "ES": {
      "country": "Spain",
      "alpha2Code": "ES",
      "alpha3Code": "ESP",
      "numericCode": 724,
      "lat": 40,
      "lon": -4,
      "emojiFlag": "🇪🇸"
  },
  "LK": {
      "country": "Sri Lanka",
      "alpha2Code": "LK",
      "alpha3Code": "LKA",
      "numericCode": 144,
      "lat": 7,
      "lon": 81,
      "emojiFlag": "🇱🇰"
  },
  "SD": {
      "country": "Sudan",
      "alpha2Code": "SD",
      "alpha3Code": "SDN",
      "numericCode": 736,
      "lat": 15,
      "lon": 30,
      "emojiFlag": "🇸🇩"
  },
  "SR": {
      "country": "Suriname",
      "alpha2Code": "SR",
      "alpha3Code": "SUR",
      "numericCode": 740,
      "lat": 4,
      "lon": -56,
      "emojiFlag": "🇸🇷"
  },
  "SJ": {
      "country": "Svalbard and Jan Mayen",
      "alpha2Code": "SJ",
      "alpha3Code": "SJM",
      "numericCode": 744,
      "lat": 78,
      "lon": 20,
      "emojiFlag": "🇸🇯"
  },
  "SZ": {
      "country": "Swaziland",
      "alpha2Code": "SZ",
      "alpha3Code": "SWZ",
      "numericCode": 748,
      "lat": -26.5,
      "lon": 31.5,
      "emojiFlag": "🇸🇿"
  },
  "SE": {
      "country": "Sweden",
      "alpha2Code": "SE",
      "alpha3Code": "SWE",
      "numericCode": 752,
      "lat": 62,
      "lon": 15,
      "emojiFlag": "🇸🇪"
  },
  "CH": {
      "country": "Switzerland",
      "alpha2Code": "CH",
      "alpha3Code": "CHE",
      "numericCode": 756,
      "lat": 47,
      "lon": 8,
      "emojiFlag": "🇨🇭"
  },
  "SY": {
      "country": "Syrian Arab Republic",
      "alpha2Code": "SY",
      "alpha3Code": "SYR",
      "numericCode": 760,
      "lat": 35,
      "lon": 38,
      "emojiFlag": "🇸🇾"
  },
  "TW": {
      "country": "Taiwan",
      "alpha2Code": "TW",
      "alpha3Code": "TWN",
      "numericCode": 158,
      "lat": 23.5,
      "lon": 121,
      "emojiFlag": "🇹🇼"
  },
  "TJ": {
      "country": "Tajikistan",
      "alpha2Code": "TJ",
      "alpha3Code": "TJK",
      "numericCode": 762,
      "lat": 39,
      "lon": 71,
      "emojiFlag": "🇹🇯"
  },
  "TZ": {
      "country": "Tanzania, United Republic of",
      "alpha2Code": "TZ",
      "alpha3Code": "TZA",
      "numericCode": 834,
      "lat": -6,
      "lon": 35,
      "emojiFlag": "🇹🇿"
  },
  "TH": {
      "country": "Thailand",
      "alpha2Code": "TH",
      "alpha3Code": "THA",
      "numericCode": 764,
      "lat": 15,
      "lon": 100,
      "emojiFlag": "🇹🇭"
  },
  "TL": {
      "country": "Timor-Leste",
      "alpha2Code": "TL",
      "alpha3Code": "TLS",
      "numericCode": 626,
      "lat": -8.55,
      "lon": 125.5167,
      "emojiFlag": "🇹🇱"
  },
  "TG": {
      "country": "Togo",
      "alpha2Code": "TG",
      "alpha3Code": "TGO",
      "numericCode": 768,
      "lat": 8,
      "lon": 1.1667,
      "emojiFlag": "🇹🇬"
  },
  "TK": {
      "country": "Tokelau",
      "alpha2Code": "TK",
      "alpha3Code": "TKL",
      "numericCode": 772,
      "lat": -9,
      "lon": -172,
      "emojiFlag": "🇹🇰"
  },
  "TO": {
      "country": "Tonga",
      "alpha2Code": "TO",
      "alpha3Code": "TON",
      "numericCode": 776,
      "lat": -20,
      "lon": -175,
      "emojiFlag": "🇹🇴"
  },
  "TT": {
      "country": "Trinidad and Tobago",
      "alpha2Code": "TT",
      "alpha3Code": "TTO",
      "numericCode": 780,
      "lat": 11,
      "lon": -61,
      "emojiFlag": "🇹🇹"
  },
  "TN": {
      "country": "Tunisia",
      "alpha2Code": "TN",
      "alpha3Code": "TUN",
      "numericCode": 788,
      "lat": 34,
      "lon": 9,
      "emojiFlag": "🇹🇳"
  },
  "TR": {
      "country": "Turkey",
      "alpha2Code": "TR",
      "alpha3Code": "TUR",
      "numericCode": 792,
      "lat": 39,
      "lon": 35,
      "emojiFlag": "🇹🇷"
  },
  "TM": {
      "country": "Turkmenistan",
      "alpha2Code": "TM",
      "alpha3Code": "TKM",
      "numericCode": 795,
      "lat": 40,
      "lon": 60,
      "emojiFlag": "🇹🇲"
  },
  "TC": {
      "country": "Turks and Caicos Islands",
      "alpha2Code": "TC",
      "alpha3Code": "TCA",
      "numericCode": 796,
      "lat": 21.75,
      "lon": -71.5833,
      "emojiFlag": "🇹🇨"
  },
  "TV": {
      "country": "Tuvalu",
      "alpha2Code": "TV",
      "alpha3Code": "TUV",
      "numericCode": 798,
      "lat": -8,
      "lon": 178,
      "emojiFlag": "🇹🇻"
  },
  "UG": {
      "country": "Uganda",
      "alpha2Code": "UG",
      "alpha3Code": "UGA",
      "numericCode": 800,
      "lat": 1,
      "lon": 32,
      "emojiFlag": "🇺🇬"
  },
  "UA": {
      "country": "Ukraine",
      "alpha2Code": "UA",
      "alpha3Code": "UKR",
      "numericCode": 804,
      "lat": 49,
      "lon": 32,
      "emojiFlag": "🇺🇦"
  },
  "AE": {
      "country": "United Arab Emirates",
      "alpha2Code": "AE",
      "alpha3Code": "ARE",
      "numericCode": 784,
      "lat": 24,
      "lon": 54,
      "emojiFlag": "🇦🇪"
  },
  "GB": {
      "country": "United Kingdom",
      "alpha2Code": "GB",
      "alpha3Code": "GBR",
      "numericCode": 826,
      "lat": 54,
      "lon": -2,
      "emojiFlag": "🇬🇧"
  },
  "US": {
      "country": "United States",
      "alpha2Code": "US",
      "alpha3Code": "USA",
      "numericCode": 840,
      "lat": 38,
      "lon": -97,
      "emojiFlag": "🇺🇸"
  },
  "UM": {
      "country": "United States Minor Outlying Islands",
      "alpha2Code": "UM",
      "alpha3Code": "UMI",
      "numericCode": 581,
      "lat": 19.2833,
      "lon": 166.6,
      "emojiFlag": "🇺🇲"
  },
  "UY": {
      "country": "Uruguay",
      "alpha2Code": "UY",
      "alpha3Code": "URY",
      "numericCode": 858,
      "lat": -33,
      "lon": -56,
      "emojiFlag": "🇺🇾"
  },
  "UZ": {
      "country": "Uzbekistan",
      "alpha2Code": "UZ",
      "alpha3Code": "UZB",
      "numericCode": 860,
      "lat": 41,
      "lon": 64,
      "emojiFlag": "🇺🇿"
  },
  "VU": {
      "country": "Vanuatu",
      "alpha2Code": "VU",
      "alpha3Code": "VUT",
      "numericCode": 548,
      "lat": -16,
      "lon": 167,
      "emojiFlag": "🇻🇺"
  },
  "VE": {
      "country": "Venezuela",
      "alpha2Code": "VE",
      "alpha3Code": "VEN",
      "numericCode": 862,
      "lat": 8,
      "lon": -66,
      "emojiFlag": "🇻🇪"
  },
  "VN": {
      "country": "Vietnam",
      "alpha2Code": "VN",
      "alpha3Code": "VNM",
      "numericCode": 704,
      "lat": 16,
      "lon": 106,
      "emojiFlag": "🇻🇳"
  },
  "VG": {
      "country": "Virgin Islands, British",
      "alpha2Code": "VG",
      "alpha3Code": "VGB",
      "numericCode": 92,
      "lat": 18.5,
      "lon": -64.5,
      "emojiFlag": "🇻🇬"
  },
  "VI": {
      "country": "Virgin Islands, U.S.",
      "alpha2Code": "VI",
      "alpha3Code": "VIR",
      "numericCode": 850,
      "lat": 18.3333,
      "lon": -64.8333,
      "emojiFlag": "🇻🇮"
  },
  "WF": {
      "country": "Wallis and Futuna",
      "alpha2Code": "WF",
      "alpha3Code": "WLF",
      "numericCode": 876,
      "lat": -13.3,
      "lon": -176.2,
      "emojiFlag": "🇼🇫"
  },
  "EH": {
      "country": "Western Sahara",
      "alpha2Code": "EH",
      "alpha3Code": "ESH",
      "numericCode": 732,
      "lat": 24.5,
      "lon": -13,
      "emojiFlag": "🇪🇭"
  },
  "YE": {
      "country": "Yemen",
      "alpha2Code": "YE",
      "alpha3Code": "YEM",
      "numericCode": 887,
      "lat": 15,
      "lon": 48,
      "emojiFlag": "🇾🇪"
  },
  "ZM": {
      "country": "Zambia",
      "alpha2Code": "ZM",
      "alpha3Code": "ZMB",
      "numericCode": 894,
      "lat": -15,
      "lon": 30,
      "emojiFlag": "🇿🇲"
  },
  "ZW": {
      "country": "Zimbabwe",
      "alpha2Code": "ZW",
      "alpha3Code": "ZWE",
      "numericCode": 716,
      "lat": -20,
      "lon": 30,
      "emojiFlag": "🇿🇼"
  },
  "AX": {
      "country": "Åland Islands",
      "alpha2Code": "AX",
      "alpha3Code": "ALA",
      "numericCode": 248,
      "lat": 60.116667,
      "lon": 19.9,
      "emojiFlag": "🇦🇽"
  },
  "BQ": {
      "country": "Bonaire, Sint Eustatius and Saba",
      "alpha2Code": "BQ",
      "alpha3Code": "BES",
      "numericCode": 535,
      "lat": 12.183333,
      "lon": -68.233333,
      "emojiFlag": "🇧🇶"
  },
  "CW": {
      "country": "Curaçao",
      "alpha2Code": "CW",
      "alpha3Code": "CUW",
      "numericCode": 531,
      "lat": 12.166667,
      "lon": -68.966667,
      "emojiFlag": "🇨🇼"
  },
  "BL": {
      "country": "Saint Barthélemy",
      "alpha2Code": "BL",
      "alpha3Code": "BLM",
      "numericCode": 652,
      "lat": 17.897728,
      "lon": -62.834244,
      "emojiFlag": "🇧🇱"
  },
  "MF": {
      "country": "Saint Martin (French part)",
      "alpha2Code": "MF",
      "alpha3Code": "MAF",
      "numericCode": 663,
      "lat": 18.075278,
      "lon": -63.06,
      "emojiFlag": "🇲🇫"
  },
  "SX": {
      "country": "Sint Maarten (Dutch part)",
      "alpha2Code": "SX",
      "alpha3Code": "SXM",
      "numericCode": 534,
      "lat": 18.033333,
      "lon": -63.05,
      "emojiFlag": "🇸🇽"
  },
  "XK": {
      "country": "Kosovo",
      "alpha2Code": "XK",
      "alpha3Code": "XKX",
      "numericCode": -1,
      "lat": 42.583333,
      "lon": 21,
      "emojiFlag": "🇽🇰"
  }
};
