import './style.scss';
import {
  Box,
  Flex,
  Text,
  Alert,
  AlertIcon,
  Skeleton,
  Center,
  useColorModeValue
} from '@chakra-ui/react';
import PortfolioCard from 'components/Portfolios/PortfolioCard';
import { Subscription } from 'data/Subscription';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import {
  getCurrentSubscriptionByUserId,
  getCurrentUserInvoicesByUserId,
  getSubscriptionSettings
} from 'repository/subscriptions';
import {
  subscriptionToStatus,
  subscriptionPlanToText,
  subscriptionToTier,
  subscriptionToNextRenewalDate,
  subscriptionCanceled
} from 'utils/Subscription';

const UserSubscription = ({ id }: { id: string }) => {
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const [invoices, setInvoices] = useState<any | null>(null);
  const { data: subscriptionSettingsData } = useQuery(['subscription-settings'], () =>
    getSubscriptionSettings()
  );
  const { isSuccess: isSubscriptionSuccess, refetch: refetchSubscription } = useQuery(
    ['subscription'],
    () => getCurrentSubscriptionByUserId(id)
  );
  const { refetch: refetchInvoices } = useQuery(['invoices'], () =>
    getCurrentUserInvoicesByUserId(id)
  );

  const subscriptionSettings = subscriptionSettingsData?.data;
  const filteredPortfolios = subscriptionSettings?.portfolioPriceSettings.filter(
    (item) => item.portfolio
  );

  const privatePortfolios = Array.from(
    new Map(
      filteredPortfolios?.map(({ portfolio }) => portfolio && [portfolio.id, portfolio])
    ).values()
  );

  const accessiblePortfolioIdsTillEndOfPeriodIdsFilter =
    subscription?.accessiblePortfolioIdsTillEndOfPeriod.filter(
      (item) => !subscription.portfolioIds.includes(item)
    );

  const privatePortfoliosCanceled = privatePortfolios.filter((item) =>
    accessiblePortfolioIdsTillEndOfPeriodIdsFilter?.includes(item.id)
  );

  console.log(privatePortfoliosCanceled);

  useEffect(() => {
    refetchSubscription().then((data) => {
      if (data.data?.data) {
        setSubscription(data.data.data);
      }
    });
    refetchInvoices().then((data) => {
      if (data.data?.data) {
        setInvoices(data.data.data);
      }
    });
  }, [id]);

  const cardBg = useColorModeValue('white', 'navy.800');

  return !isSubscriptionSuccess ? (
    <Skeleton
      width={'100%'}
      height={'400px'}
      speed={2}
      opacity={0.9}
      borderRadius={'8px'}
      boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
    />
  ) : subscription ? (
    <Flex
      width="320px"
      flexDirection={'column'}
      justifyContent={'space-between'}
      borderRadius={'8px'}
      boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
      bg={cardBg}
      height={'fit-content'}
      py={4}
      px={8}
      flexGrow={1}>
      <Box p={4} />
      <Flex flexDirection="column" height="fit-content" overflow="hidden" gap={5}>
        <Flex className="passport-item__info" justifyContent={'space-between'} width="100%">
          <Flex flexDirection={'column'} mr="15px">
            <Text fontSize="lg" fontWeight="regular">
              статус
            </Text>
            <Box p={1} />
            <Text fontSize="lg" fontWeight="bold">
              {subscriptionToStatus(subscription)}
            </Text>
          </Flex>
          <Flex flexDirection={'column'} mr="15px">
            <Text fontSize="lg" fontWeight="regular">
              план
            </Text>
            <Box p={1} />
            <Text fontSize="lg" fontWeight="bold">
              {subscriptionPlanToText(subscription.plan)}
            </Text>
          </Flex>
          <Flex flexDirection={'column'} mr="15px">
            <Text fontSize="lg" fontWeight="regular">
              длительность
            </Text>
            <Box p={1} />
            <Text fontSize="lg" fontWeight="bold">
              {subscriptionToTier(subscription)}
            </Text>
          </Flex>
          <Flex flexDirection={'column'} mr="15px">
            <Text fontSize="lg" fontWeight="regular">
              {subscription.isActive ? 'следующее списание' : 'доступно до'}
            </Text>
            <Box p={1} />
            <Text fontSize="lg" fontWeight="bold">
              {subscriptionToNextRenewalDate(subscription)}
            </Text>
          </Flex>
          {subscription.unsubscribedAtTimestamp ? (
            <Flex flexDirection={'column'}>
              <Text fontSize="lg" fontWeight="regular" textTransform="lowercase">
                отменена
              </Text>
              <Box p={1} />
              <Text fontSize="lg" fontWeight="bold">
                {subscriptionCanceled(subscription)}
              </Text>
            </Flex>
          ) : null}
          {subscription?.upcomingInvoice?.total ? (
            <Flex flexDirection={'column'}>
              <Text fontSize="lg" fontWeight="regular">
                стоимость
              </Text>
              <Box p={1} />
              <Text fontSize="lg" fontWeight="bold">
                {subscription?.upcomingInvoice?.total / 100}₽
              </Text>
            </Flex>
          ) : null}
        </Flex>
        {!subscription.isActive ? (
          <>
            <Box p={2} />
            <Alert fontSize="18px" fontWeight="500" status="info" borderRadius={15}>
              <AlertIcon />
              <Text
                mr={
                  1.5
                }>{`Подписка отменена. Подписка будет доступна до ${subscriptionToNextRenewalDate(
                subscription
              )}`}</Text>
            </Alert>
            <Box p={2} />
          </>
        ) : null}
        <Flex className="portfolio-card__wrapp" flexGrow={1} overflow="scroll" zIndex={100}>
          <Flex width={'fit-content'} flexDirection={'row'} alignItems="center" gap={4} zIndex={0}>
            {subscription.portfolios.map(
              (portfolio) =>
                portfolio && (
                  <PortfolioCard
                    id={portfolio.id}
                    color={`#${portfolio.color}`}
                    name={portfolio.name}
                    risk={portfolio.risk}
                    assets={portfolio.assets}
                    yieldPercentage={portfolio.yieldPercentage}
                    bondsYieldPercentage={portfolio.bondsYieldPercentage}
                    cashPercentage={portfolio.cashPercentage}
                    subscriptionId={subscription.id}
                    subscriptionPlan={subscription.plan}
                    subscriptionTier={subscription.tier}
                    portfolioIds={subscription.portfolioIds}
                    portfoliosQuantity={subscription.portfolios?.length}
                    editable={true}
                  />
                )
            )}
            {privatePortfoliosCanceled.map(
              (portfolio) =>
                portfolio && (
                  <PortfolioCard
                    id={portfolio.id}
                    color={`#${portfolio.color}`}
                    name={portfolio.name}
                    risk={portfolio.risk}
                    assets={portfolio.assets}
                    yieldPercentage={portfolio.yieldPercentage}
                    bondsYieldPercentage={portfolio.bondsYieldPercentage}
                    cashPercentage={portfolio.cashPercentage}
                    subscriptionId={subscription.id}
                    subscriptionPlan={subscription.plan}
                    subscriptionTier={subscription.tier}
                    portfolioIds={subscription.portfolioIds}
                    portfoliosQuantity={subscription.portfolios?.length}
                    editable={true}
                    canceled={true}
                  />
                )
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  ) : (
    <Center width={'100%'} height="xs">
      <Text fontSize={'xl'} fontWeight={'bold'} m={'auto'} mx={4} textAlign="center">
        Пользователь не имеет активной подписки
      </Text>
    </Center>
  );
};

export default UserSubscription;
