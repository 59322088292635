import { ResultSet } from "@cubejs-client/core";
import { useDeepCompareMemo } from "use-deep-compare";
import { useEffect, useState } from "react";
import { geoEqualEarth, geoPath } from "d3-geo";
import { feature } from "topojson-client";

import { ChartRenderer, PivotConfig } from "components/Charts/ChartRenderer";
import { ChartCategory, Chart as IChart } from "../interfaces/Chart";
import { COUNTRIES } from "constants/countries";

const getMapData = (resultSet: any): {countryCode: string, count: number, country: typeof COUNTRIES['AF']}[] => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }: any) =>
      yValuesArray.map(([yValues, m]: any) => ({
        countryCode: xValues[0],
        count: m && Number.parseFloat(m),
      }))
    )
    .reduce((a: any, b: any) => a.concat(b), [])
    .filter((d: any) => d.countryCode)
    .map((a: any) => ({...a, country: COUNTRIES[a.countryCode as keyof typeof COUNTRIES]}))
  return data;
};

const projection = geoEqualEarth().scale(160)

const WorldMapChartRenderer = ({
  resultSet,
  pivotConfig,
}: {
  resultSet: ResultSet;
  pivotConfig: PivotConfig;
}) => {
  const data = useDeepCompareMemo(
    () => getMapData(resultSet),
    [resultSet.serialize()]
  );

  const [geographies, setGeographies] = useState([]);
  useEffect(() => {
    fetch("https://cdn.jsdelivr.net/npm/world-atlas@2/countries-110m.json")
      .then(response => {
        if (response.status !== 200) {
          console.log(`There was a problem: ${response.status}`)
          return
        }
        response.json().then(worlddata => {
          setGeographies((feature(worlddata, worlddata.objects.countries) as any).features)
        })
      })
  }, []);

  const handleCountryClick = (countryIndex: number) => {
    console.log("Clicked on country: ", geographies[countryIndex])
  }

  const handleMarkerClick = (i: number) => {
    console.log("Marker: ", data[i])
  }

  const sum = data.reduce((a, b) => a + b.count, 0);
  
  return (
    <svg width={ '1000' } height={ '400' } viewBox="100 90 800 220">
      <g className="countries">
        {
          geographies.map((d,i) => (
            <path
              key={ `path-${ i }` }
              d={ geoPath().projection(projection)(d) ?? undefined }
              className="country"
              fill={ `rgba(38,50,56,${ 0.2 + (1 / geographies.length * (i + 1)) * 0.8})` }
              stroke="#FFFFFF"
              strokeWidth={ 0.5 }
              onClick={ () => handleCountryClick(i) }
            />
          ))
        }
      </g>
      <g className="markers">
        {
          data.map((a, i) => (
            <circle
              key={ `marker-${i}` }
              cx={ projection([a.country.lon, a.country.lat])?.[0] }
              cy={ projection([a.country.lon, a.country.lat])?.[1] }
              r={ Math.log(a.count / sum * 200) }
              fill="#E91E63"
              stroke="#FFFFFF"
              className="marker"
              onClick={ () => handleMarkerClick(i) }
            />
          ))
        }
      </g>
    </svg>
  );
};

export const SessionsByCountryCodeMap: IChart = {
  id: "sessions_by_country_code",
  name: "Сессии по странам",
  category: ChartCategory.SESSIONS,
  element: () => {
    return (
      <ChartRenderer
        width={'100%'}
        height={400}
        renderOnlyElement={true}
        chartProps={{
          id: "sessions_by_country_code",
          title: "Сессии по странам",
          query: {
            "measures": [
              "Sessions.count"
            ],
            "order": {
              "Sessions.count": "desc"
            },
            "timeDimensions": [
              {
                "dimension": "Sessions.createdat",
                "dateRange": "Last 30 days"
              }
            ],
            "dimensions": [
              "Sessions.locationCountrycode"
            ]
          },
          pivotConfig: {
            "x": [
              "Sessions.locationCountrycode"
            ],
            "y": [
              "measures"
            ],
            "fillMissingDates": true,
            "joinDateRange": false,
          },
          element: WorldMapChartRenderer,
        }}
      />
    );
  },
};
