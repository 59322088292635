import { Portfolio } from './Portfolio';

export enum BillingReason {
  SUBSCRIPTION_CREATE = 'SUBSCRIPTION_CREATE',
  SUBSCRIPTION_UPDATE = 'SUBSCRIPTION_UPDATE',
  SUBSCRIPTION_CYCLE = 'SUBSCRIPTION_CYCLE',
  UPCOMING = 'UPCOMING',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum InvoiceStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum PaymentStatus {
  AUTHORIZED = 'AUTHORIZED',
  REVERSED = 'REVERSED',
  CONFIRMED = 'CONFIRMED',
  PARTIAL_REFUNDED = 'PARTIAL_REFUNDED',
  PARTIAL_REVERSED = 'PARTIAL_REVERSED',
  REFUNDED = 'REFUNDED',
  REJECTED = 'REJECTED',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export interface Card {
  cardId: string;
  pan: string;
  status: string;
  expirationDate: string;
  isDefault: boolean;
}

export interface Transaction {
  id: string;
  paymentId?: string | undefined;
  userId: string;
  invoiceId: string;
  amount: number;
  isSuccess: boolean;
  card?: Card | undefined;
  status: PaymentStatus;
  paymentUrl?: string | undefined;
  errorCode?: string | undefined;
  errorMessage?: string | undefined;
  errorDetails?: string | undefined;
  parentTransactionId?: string | undefined;
  productId?: string | undefined;
}

export interface InvoiceItem {
  name: string;
  quantity: number;
  unitPrice: number;
  amount: number;
}
export interface Invoice {
  timestamp: number;
  subtotal: number;
  total: number;
  id: string;
  subscriptionId: string;
  customerId: string;
  amountPaid: number;
  amountRemaining: number;
  attemptCount: number;
  attempted: boolean;
  billingReason: BillingReason;
  invoicePdf?: string | undefined;
  receiptPdf?: string | undefined;
  createdAtTimestamp: number;
  dueDateTimestamp: number;
  nextPaymentAttemptTimestamp?: number | undefined;
  status: InvoiceStatus;
  latestAttempt?: Transaction;
  items: InvoiceItem[];
  discountPercentage: number;
}

export enum SubscriptionTier {
  MONTHLY = 'MONTHLY',
  THREE_MONTHLY = 'THREE_MONTHLY',
  SIX_MONTHLY = 'SIX_MONTHLY',
  YEARLY = 'YEARLY',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum SubscriptionPlan {
  BASIC = 'BASIC',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum DurationUnit {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum SubscriptionPaymentStatus {
  PAYMENT_OK = 'PAYMENT_OK',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export interface SubscriptionPlanSettings {
  plan: SubscriptionPlan;
  /** discount in percents e.g. 10% = 10 */
  portfoliosMaxDiscount: number;
  discount: number;
  minPortfolios: number;
  maxPortfolios: number;
  isTrialEnabled: boolean;
  trialDurationUnit?: DurationUnit | undefined;
  trialDuration?: number | undefined;
}

export interface PortfolioPriceSettings {
  portfolioId: string;
  tier: SubscriptionTier;
  /** price in currency units e.g. 2.99$ = 299 */
  discount: number;
  price: number;
  isEnabled: boolean;
  isPublic: boolean;
}

export interface Subscription {
  id: string;
  userId: string;
  plan: SubscriptionPlan;
  tier: SubscriptionTier;
  planSettings?: SubscriptionPlanSettings;
  portfolioIds: string[];
  accessiblePortfolioIdsTillEndOfPeriod: string[];
  portfolioPriceSettings: PortfolioPriceSettings[];
  lastRenewalTimestamp?: number | undefined;
  nextRenewalTimestamp: number;
  currentPeriodStartsAtTimestamp: number;
  currentPeriodExpiresAtTimestamp: number;
  subscribedAtTimestamp: number;
  unsubscribedAtTimestamp?: number | undefined;
  unsubscribeReason?: string | undefined;
  trialPeriodStartsAtTimestamp?: number | undefined;
  trialPeriodExpiresAtTimestamp?: number | undefined;
  isActive: boolean;
  paymentStatus: SubscriptionPaymentStatus;
  suspendedUntilTimestamp?: number | undefined;
  suspendReason?: string | undefined;
  discountCode?: string | undefined;
  prefferedPaymentMethodId?: string | undefined;
  upcomingInvoice?: Invoice | undefined;
  portfolios: Portfolio[];
  latestInvoice?: Invoice;
}

export interface Invoice {
  id: string;
  subscriptionId: string;
  customerId: string;
  subtotal: number;
  total: number;
  amountPaid: number;
  amountRemaining: number;
  attemptCount: number;
  attempted: boolean;
  billingReason: BillingReason;
  invoicePdf?: string | undefined;
  receiptPdf?: string | undefined;
  createdAtTimestamp: number;
  dueDateTimestamp: number;
  nextPaymentAttemptTimestamp?: number | undefined;
  status: InvoiceStatus;
  latestAttempt?: Transaction;
  items: InvoiceItem[];
  discountPercentage: number;
}
