import { Box, Flex } from "@chakra-ui/react";
import { NewSubscriptionsLast30Days } from "components/Charts/InlineCharts/NewSubscriptionsLast30Days";
import { NewReferralsLast30Days } from "components/Charts/InlineCharts/NewReferralsLast30Days";
import { NewSessionsLast30Days } from "components/Charts/InlineCharts/NewSessionsLast30Days";
import { NewUsersLast30Days } from "components/Charts/InlineCharts/NewUsersLast30Days";

import { SessionsByCountryCodeMap } from "components/Charts/Sessions/SessionsByCountryCodeMap";
import { UsersTable } from "components/Tables/UsersTable";
import AppLayout from "layouts/AppLayout";

export const Users = () => {
  const UserSessionsMap = SessionsByCountryCodeMap.element;

  return (
    <AppLayout>
      <Flex
        flexDirection={'column'}
        gap={4}
      >
        <UserSessionsMap />
        <Flex
          ml={"-60px"}
          mr={"-45px"}
          flexGrow={1}
          overflow="scroll"
          padding={2}
        >
          <Flex
            width={"fit-content"}
            flexDirection={"row"}
            px={"auto"}
            alignItems="center"
            gap={4}
          >
            <Box w={"35px"} />
            <NewUsersLast30Days />
            <NewSubscriptionsLast30Days />
            <NewSessionsLast30Days />
            <NewReferralsLast30Days />
          </Flex>
        </Flex>
        <UsersTable />
      </Flex>
    </AppLayout>
  );
};