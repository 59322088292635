import { CartIcon, HomeIcon, PersonIcon } from 'components/Icons/Icons';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import LoginPage from 'pages/Login';
import { Subscriptions } from 'pages/Subscriptions/Subscriptions';
import { UserCard } from 'pages/Users/UserCard';
import { Users } from 'pages/Users/Users';

export interface AppRoute {
  path: string;
  element: React.ReactNode;
  name: string;
  hidden?: boolean;
  icon?: React.ReactNode | null;
  children?: Record<string, AppRoute>;
}

export const AppRoutes = {
  Login: {
    path: '/login',
    name: 'Login',
    icon: null,
    element: <LoginPage />,
    hidden: true
  },
  Dashboard: {
    path: '/dashboard',
    name: 'Панель управления',
    icon: <HomeIcon color="inherit" />,
    element: <Dashboard />
  },
  Subscriptions: {
    path: '/subscriptions',
    name: 'Подписки',
    icon: <CartIcon />,
    element: <Subscriptions />
  },
  Users: {
    path: '/users',
    name: 'Пользователи',
    icon: <PersonIcon />,
    element: <Users />,
    children: {
      User: {
        hidden: true,
        path: '/:id',
        name: 'Пользователь',
        element: <UserCard />
      }
    }
  }
  // Mailing: {
  //   path: '/mailing',
  //   name: 'Mailing',
  //   icon: <></>,
  //   element: <></>,
  // },
  // Analytics: {
  //   path: '/analytics',
  //   name: 'Аналитика',
  //   icon: <StatsIcon color='inherit' />,
  //   element: <></>,
  //   children: {
  //     Users: {
  //       path: '/users',
  //       name: 'Пользователи',
  //       icon: <></>,
  //       element: <></>,
  //     },
  //     Subscriptions: {
  //       path: '/subscriptions',
  //       name: 'Подписки',
  //       icon: <></>,
  //       element: <></>,
  //     },
  //     Activity: {
  //       path: '/activity',
  //       name: 'Активность',
  //       icon: <></>,
  //       element: <></>,
  //     },
  //   },
  // },
};

const flattenRoutes = (routes: Record<string, AppRoute>, basePath = ''): AppRoute[] => {
  return Object.keys(routes).flatMap((key) => {
    const route = routes[key];

    if (route.children) {
      return [
        { ...route, children: undefined, path: basePath + route.path },
        ...flattenRoutes(route.children, basePath + route.path)
      ];
    }

    return [{ ...route, children: undefined, path: basePath + route.path }];
  });
};

export const FlatAppRoutes = flattenRoutes(AppRoutes);
