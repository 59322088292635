import { SUBSCRIPTIONS_TIERS } from 'data/endpoints';
import { putRequest } from 'utils/baseRequest';
import { TiersItem } from './getTiers';

interface TierResponse {
  subscriptionTierSettings: TiersItem[];
}

export const putTiers = async (discountData: any) => {
  try {
    const payload = {
      tiers: discountData
    };
    const response = await putRequest<TierResponse>(SUBSCRIPTIONS_TIERS, payload);

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false, data: undefined };
  }
};
