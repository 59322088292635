import './styles.scss';
import { Dispatch, useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Spinner,
  Table as TableCk,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue
} from '@chakra-ui/react';
import { Column, useSortBy, useTable } from 'react-table';
import { User } from 'data/User';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { useUsersCtx } from 'state/UsersContext';

export const DEFAULT_LIMIT = 30;
export interface CellItem {
  value: string;
  row: { values: BasicPayload & { isActive: boolean }; original: { [p: string]: any } };
}

interface TableProps {
  columns: ReadonlyArray<Column>;
  data: readonly object[];
  onElementClick?: (row: any) => void;
  setData?: Dispatch<React.SetStateAction<User[]>>;
  refetch?: any;
}

const Table = (props: TableProps) => {
  const { columns, data, setData, refetch } = props;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
      // @ts-ignore
      autoResetPage: false,
      autoResetFilters: false,
      autoResetSortBy: false
    },
    useSortBy
  );
  const { setCurrPage } = useUsersCtx();
  const [isPaginatioLoading, setIsPaginatioLoading] = useState<boolean>(false);
  const listInnerRef = useRef(null);
  const [lastList, setLastList] = useState<boolean>(false);
  const [bottom, setBottom] = useState<boolean>(false);
  const isSorted = // @ts-ignore
    headerGroups.find((headerGroup) => headerGroup.headers.some((column) => column.isSorted))
      ? 'sorted'
      : 'none';

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight && !bottom) {
        setBottom(true);
        setCurrPage?.((prev) => prev + 1);
      }
    }
  };

  useEffect(() => {
    if (bottom) {
      const fetchData = async () => {
        setIsPaginatioLoading(true);
        const resp = await refetch();
        const respUsers = resp?.data?.data?.users;

        if (!respUsers.length) {
          setLastList(true);
          return;
        }

        if (resp?.data?.ok && respUsers) {
          if (isSorted !== 'none' && listInnerRef && listInnerRef.current) {
            // @ts-ignore
            listInnerRef.current.scrollTo(0, 0);
          }
          setData?.((prev) => [...prev, ...respUsers]);
          setIsPaginatioLoading(false);
          setBottom(false);
        } else {
          setIsPaginatioLoading(false);
          setBottom(false);
        }
      };

      if (!lastList) {
        fetchData();
        setIsPaginatioLoading(false);
        setBottom(false);
      }
    }
  }, [bottom]);

  const cardBg = useColorModeValue('white', 'navy.800');

  return (
    <Box
      className="table-wrapp"
      height={`${data.length ? '500px' : 'auto'}`}
      overflow="auto"
      onScroll={onScroll}
      ref={listInnerRef}>
      <TableCk {...getTableProps()}>
        <Thead
          style={{
            position: 'sticky',
            top: 0,
            backgroundColor: cardBg,
            zIndex: 2
          }}>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <Th
                  //  @ts-ignore
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  // todo
                  //  @ts-ignore
                  pointerEvents={column.sort === false ? 'none' : 'all'}
                  //  @ts-ignore
                  className={column.sort === false ? '' : 'sorted'}
                  fontWeight={400}
                  fontSize={11}
                  letterSpacing="0.02em"
                  textTransform="none"
                  paddingLeft={1}
                  paddingRight={1}
                  backgroundColor={`${cardBg}`}
                  maxWidth={column.maxWidth}
                  width={column.width}
                  // @ts-ignore
                  textAlign={column.textAlign}
                  // @ts-ignore
                  {...column.style}>
                  <Flex justifyContent="flex-start" alignItems="center">
                    {column.render('Header')}
                    {/* @ts-ignore */}
                    {column.isSorted ? (
                      column.id === 'subscriptionActive' ? (
                        // @ts-ignore
                        column.isSortedDesc ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ) // @ts-ignore
                      ) : column.isSortedDesc ? (
                        <ChevronDownIcon />
                      ) : (
                        <ChevronUpIcon />
                      )
                    ) : null}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} onClick={() => props.onElementClick?.(row.original)}>
                {row.cells.map((cell) => (
                  <Td
                    {...cell.getCellProps()}
                    /* isNumeric={cell.column.isNumeric} */
                    fontWeight={500}
                    fontSize={12}
                    paddingLeft={1}
                    paddingRight={1}
                    maxHeight={60}
                    paddingTop={3}
                    paddingBottom={3}
                    // @ts-ignore
                    textAlign={cell.column.textAlign}
                    maxWidth={cell.column.maxWidth}
                    // @ts-ignore
                    {...cell.column.style}>
                    {cell.render('Cell')}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </TableCk>
      {!data.length ? (
        <Flex fontWeight="700" justifyContent="center" alignItems="center" paddingTop="15px">
          Нет результатов
        </Flex>
      ) : isPaginatioLoading ? (
        <Flex justifyContent="center" alignItems="center" paddingTop="15px">
          <Spinner />
        </Flex>
      ) : null}
    </Box>
  );
};

export default Table;
