import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  useColorModeValue
} from "@chakra-ui/react";
import { PropsWithChildren, useEffect, useState } from "react";
import AdminNavbarLinks from "./AdminNavbarLinks";

export interface AdminNavbarProps {
  brandText: string;
  brandTextLink: string;
  fixed: boolean;
  onOpen: () => void;
}

export default function AdminNavbar(props: PropsWithChildren<AdminNavbarProps>) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);
    return () => {
      window.removeEventListener("scroll", changeNavbar);
    }
  });

  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const {
    fixed,
    brandText,
  } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue((fixed && scrolled) ? "gray.700" : "white", "gray.200");
  let secondaryText = useColorModeValue((fixed && scrolled) ? "gray.700" : "white", "gray.200");
  let navbarPosition: 'fixed' | 'absolute' = fixed && scrolled ? "fixed" : "absolute" ;
  let navbarFilter = useColorModeValue(
    "none",
    "drop-shadow(0px 7px 23px rgba(0, 0, 0, 0.05))"
  );
  let navbarBackdrop = "none";
  let navbarShadow = useColorModeValue(
    "0px 7px 23px rgba(0, 0, 0, 0.05)",
    "none"
  );
  let navbarBg = useColorModeValue(
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  let navbarBorder = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.31)");
  let secondaryMargin = "0px";

  if (!fixed || !scrolled) {
    navbarFilter = "none";
    navbarBackdrop = "none";
    navbarShadow = "none";
    navbarBg = "none";
    navbarBorder = "transparent";
  }

  return (
    <Flex
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={"flex-end"}
      borderRadius="16px"
      display="flex"
      minH="75px"
      justifyContent={'center'}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={"30px"}
      px={'3opx'}
      ps={'12px'}
      pt="8px"
      top="12px"
      w={"calc(100vw - 75px - 275px)"}
    >
      <Flex
        w="100%"
        flexDirection={"row"}
        alignItems={"center"}
      >
        <Box mb={{ sm: "8px", md: "0px" }}>
          <Breadcrumb>
            <BreadcrumbItem color={mainText}>
              <BreadcrumbLink href="/" color={secondaryText}>
                Главная
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem color={mainText}>
              <BreadcrumbLink href={props.brandTextLink} color={mainText}>
                {/* {brandText} */}
              </BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
          <Link
            color={mainText}
            href="#"
            bg="inherit"
            borderRadius="inherit"
            fontWeight="bold"
            _hover={{ color: { mainText } }}
            _active={{
              bg: "inherit",
              transform: "none",
              borderColor: "transparent",
            }}
            _focus={{
              boxShadow: "none",
            }}
          >
            {brandText}
          </Link>
        </Box>
        <Box ms="auto" w={{ sm: "100%", md: "unset" }}>
          <AdminNavbarLinks
            onOpen={props.onOpen}
            fixed={props.fixed}
            scrolled={scrolled}
          />
        </Box>
      </Flex>
    </Flex>
  );
}