import {
  TableContainer,
  Table,
  Thead,
  Th,
  Tbody,
  Tr,
  Td,
  Flex,
  Button,
  useToast,
  useColorModeValue
} from '@chakra-ui/react';
import { EditableNumberCell } from 'pages/Subscriptions/Subscriptions';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { getTiers, TiersItem } from 'repository/discount/getTiers';
import { putTiers } from 'repository/discount/putTiers';

const DiscountTable = () => {
  const { isSuccess } = useQuery(['tiers'], () =>
    getTiers().then((resp) => {
      if (resp.data?.subscriptionTierSettings) {
        setDiscountState(resp.data?.subscriptionTierSettings);
      }
    })
  );
  const [discountState, setDiscountState] = useState<TiersItem[]>([]);
  const toast = useToast();

  const discountConverter = (discount: string) => {
    switch (discount) {
      case 'MONTHLY':
        return '1 месяц';
      case 'THREE_MONTHLY':
        return '3 месяца';
      case 'SIX_MONTHLY':
        return '6 месяцев';
      case 'YEARLY':
        return '12 месяцев';
      default:
        return 'N/A';
    }
  };

  const saveDiscount = useMutation(['save-discount'], (discountData: any) =>
    putTiers(discountData).then((data) => {
      if (data.ok) {
        if (data.data) {
          setDiscountState(data.data.subscriptionTierSettings);
          toast({
            title: 'Данные сохранены!',
            status: 'success',
            duration: 2000,
            isClosable: true
          });
        }
      } else {
        toast({
          title: 'Ошибка!',
          status: 'error',
          duration: 2000,
          isClosable: true
        });
      }
    })
  );

  const cardBg = useColorModeValue('white', 'navy.800');

  return (
    <Flex flexDirection="column">
      <Flex
        flexDirection="column"
        width="100%"
        borderRadius="8px"
        boxShadow="0px 0px 8px rgba(0, 0, 0, 0.1)"
        backgroundColor={cardBg}
        mb="15px">
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>Подписки</Th>
                <Th isNumeric>Скидка</Th>
              </Tr>
            </Thead>
            <Tbody>
              {discountState ? (
                discountState.map((itemState) => (
                  <Tr>
                    <Td>{discountConverter(itemState.name)}</Td>
                    <Td isNumeric>
                      <EditableNumberCell
                        value={itemState.discount ? itemState.discount : undefined}
                        isPercent
                        onChange={(value) => {
                          setDiscountState((prev) => {
                            return prev.map((item) => {
                              if (item.id === itemState.id) {
                                return { ...item, discount: +value };
                              }
                              return item;
                            });
                          });
                        }}
                      />
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td>Нет данных</Td>
                  <Td>Нет данных</Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      <Button
        maxW={'500px'}
        _focus={{
          outline: 'none'
        }}
        colorScheme={'green'}
        m={'auto'}
        isLoading={saveDiscount.isLoading}
        onClick={() =>
          saveDiscount.mutate(
            discountState.map((item) => ({
              id: item.id,
              discount: Number(item.discount)
            }))
          )
        }>
        Сохранить текущие скидки
      </Button>
    </Flex>
  );
};

export default DiscountTable;
