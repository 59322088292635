import {
  Box,
  Flex,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { getCurrentUserInvoicesByUserId } from 'repository/subscriptions';
import { billingReasonToText } from 'utils/Subscription';

const UserInvoices = ({ id }: { id: string }) => {
  const { data: invoicesData } = useQuery(['invoices'], () => getCurrentUserInvoicesByUserId(id));
  const invoices = invoicesData?.data?.invoices;
  const cardBg = useColorModeValue('white', 'navy.800');

  return invoices && invoices.length ? (
    <Flex
      className="block"
      flexDirection="column"
      height="fit-content"
      overflow="hidden"
      borderRadius={'8px'}
      boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
      bg={cardBg}
      gap={5}
      py={4}
      px={8}>
      <Text className="passport-title">История платежей</Text>
      <Box p={0} />
      <Box overflow="auto">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>ДАТА</Th>
              <Th>ОПИСАНИЕ</Th>
              <Th>СУММА</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {invoices.map((invoice) => (
              <Tr fontSize={'14px'}>
                <Td>
                  {new Date(invoice.createdAtTimestamp).toLocaleDateString('ru-RU', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric'
                  })}
                </Td>
                <Td>{billingReasonToText(invoice.billingReason)}</Td>
                <Td>{(invoice.total / 100).toFixed(2)}₽</Td>
                <Td textAlign={'left'} alignItems={'center'} verticalAlign={'middle'}>
                  скачать{' '}
                  <a style={{ color: '#0069ff' }} href={invoice.invoicePdf} download>
                    счет
                  </a>
                  {invoice.receiptPdf && (
                    <span>
                      {' • '}
                      <a style={{ color: '#0069ff' }} href={invoice.receiptPdf} download>
                        чек
                      </a>
                    </span>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>
    </Flex>
  ) : null;
};

export default UserInvoices;
