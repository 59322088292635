import { Text } from '@chakra-ui/react';
import { CellItem } from '.';

const NormalCell = (props: CellItem) => {
  const { value } = props;

  return (
    <Text my="auto" fontWeight={400} color="inherit">
      {value || '-'}
    </Text>
  );
};

export default NormalCell;
