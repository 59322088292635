import { Subscription } from './Subscription';

export interface Avatar {
  avatarUrl: string;
  sm: string;
  md: string;
  lg: string;
}

export interface Location {
  region?: string | undefined;
  city?: string | undefined;
  locale?: string | undefined;
  timezone?: string | undefined;
  utcOffset?: number | undefined;
}

export interface User {
  id: string;
  accountType: User_AccountType;
  isInBlacklist: boolean;
  username: string;
  firstName?: string | undefined;
  surname?: string | undefined;
  gender?: User_Gender | undefined;
  emailAddress?: string | undefined;
  isEmailAddressVerified: boolean;
  phoneNumber?: string | undefined;
  isPhoneNumberVerified: boolean;
  avatar?: Avatar;
  birthDate?: string | undefined;
  location?: Location;
  subscriptions?: Subscription[];
}

export enum User_Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  GENDERLESS = 'GENDERLESS',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum User_AccountType {
  /** USER - Regular user */
  USER = 'USER',
  /** INTERNAL - Employees, Users of internal system */
  INTERNAL = 'INTERNAL',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export interface UsersIteratorFilter {
  email?: string | undefined;
  phoneNumber?: string | undefined;
  name?: string | undefined;
  subscriptionPlan?: string | undefined;
  subscriptionTier?: string | undefined;
  withSubscriptions?: boolean | undefined;
}
