import { SettingsIcon } from "@chakra-ui/icons";
import {
  Button,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useAuth } from "state/AuthContext";
import "./styles.scss";

const UserPopup = () => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const { logOut } = useAuth();

  let navbarIcon = useColorModeValue("white", "gray.200");

  return (
    <Popover
      placement="bottom-start"
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
    >
      <PopoverTrigger>
        <Button
          style={{
            backgroundColor: "transparent",
            border: "0px",
            width: '18px!important',
            height: '18px',
            padding: '0px',
            margin: '0px',
          }}
          _focus={{ border: "0px", boxShadow: "none" }}
          as={IconButton}
          icon={
            <SettingsIcon
              cursor="pointer"
              ms={{ base: "16px", xl: "0px" }}
              me="16px"
              color={navbarIcon}
              w="18px"
              h="18px"
            />
          }
        />
      </PopoverTrigger>
      <PopoverContent style={{ width: "200px" }} _focus={{outline: 'none'}}>
        <PopoverBody className="user-popup__body">
          <Button className="exit" onClick={() => logOut()}>
            Выйти
          </Button>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default UserPopup;
