export enum ChartCategory {
  SESSIONS = "Sessions",
  USERS = "Users",
  SUBSCRIPTIONS = "Subscriptions",
}

export interface Chart {
  id: string;
  name: string;
  category: ChartCategory;
  element: () => JSX.Element;
}
