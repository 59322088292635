import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { AuthStorageItem } from '../constants';
import useLocalStorage from '../utils/useLocalStorage';

type AuthContextType = {
  loggedIn: boolean;
  logIn: (t: {access_token: string}) => Promise<void>;
  logOut: VoidFunction;
  token?: string | null;
};

export const AuthContext = createContext<AuthContextType>({
  token: null,
  loggedIn: false,
  logOut: () => null,
  logIn: async () => {
    return;
  },
});

const { Provider } = AuthContext;

const AuthContextProvider = (props: PropsWithChildren<{}>) => {
  const [token, setToken] = useLocalStorage<string>(AuthStorageItem, '');
  const [loggedIn, setLoggedIn] = useState(() => !!token);

  const logOut = useCallback(async () => {
    await setLoggedIn(false);
    setToken('');
  }, [setToken]);

  const logIn = useCallback(
    async (data: {access_token: string}) => {
      await setLoggedIn(true);
      await setToken(data.access_token);
    },
    [setToken],
  );

  const value = useMemo(
    () => ({ token, loggedIn, logIn, logOut }),
    [token, loggedIn, logIn, logOut],
  );

  return <Provider value={value}>{props.children}</Provider>;
};

export default AuthContextProvider;

export function useAuth() {
  return useContext(AuthContext);
}
