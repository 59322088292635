import { Transaction } from 'data/Payment';
import { Subscription } from 'data/Subscription';

export interface I18N {
  /** locale name that is used in properties by default */
  defaultLocale?: string | undefined;
  /** extra locales */
  locales: { [key: string]: I18N_LocaleEntry };
}

export interface I18N_LocaleEntry {
  fields: { [key: string]: string };
}

export interface I18N_LocaleEntry_FieldsEntry {
  key: string;
  value: string;
}

export interface I18N_LocalesEntry {
  key: string;
  value?: I18N_LocaleEntry;
}

export interface HistoricalYield {
  timestamp: number;
  yieldPercentage: number;
}

export interface PortfolioAsset {
  name: string;
  ticker: string;
  asset?: PortfolioAsset_Asset;
  shareInPortfolio: number;
  quantity: number;
  boughtAtTimestamp: number;
  relevance: PortfolioAsset_Relevance;
  boughtPrice: number;
  currentPrice: number;
  dividendYieldPercentage?: number | undefined;
  pnl: number;
}

export enum PortfolioAsset_Relevance {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export interface PortfolioAsset_Asset {
  id: string;
  ticker: string;
  adapterName?: string | undefined;
}

export enum Portfolio_Risk {
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export interface BondsYieldPercentage {
  averageBondsYieldYear: number;
  bondsTotal: number;
  bondsYieldYear: number;
}

export interface Portfolio {
  id: string;
  stateId?: string | undefined;
  name: string;
  description?: string | undefined;
  /** internazionalization of name, description fields */
  i18n?: I18N | undefined;
  color: string;
  risk: Portfolio_Risk;
  cashPercentage: number;
  yieldPercentage: Portfolio_YieldPercentage;
  bondsYieldPercentage: BondsYieldPercentage;
  historicalYield: HistoricalYield[];
  /** repeated models.Deal deals = 9; */
  assets: PortfolioAsset[];
  createdAtTimestamp: number;
  lastUpdatedAtTimestamp: number;
}

export const riskToText = (risk: Portfolio_Risk) => {
  switch (risk) {
    case Portfolio_Risk.LOW:
      return `низкий`;
    case Portfolio_Risk.MEDIUM:
      return `средний`;
    case Portfolio_Risk.HIGH:
      return `высокий`;
  }
};

export interface Portfolio_YieldPercentage {
  total: number;
  year: number;
  month: number;
  dividendYear?: number;
}

export interface AdditionalFields {
  assets: boolean;
  yieldPercentage: boolean;
  cashPercentage: boolean;
  historicalYield: boolean;
}

export enum SubscriptionTier {
  MONTHLY = 'MONTHLY',
  THREE_MONTHLY = 'THREE_MONTHLY',
  SIX_MONTHLY = 'SIX_MONTHLY',
  YEARLY = 'YEARLY',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum SubscriptionPlan {
  BASIC = 'BASIC',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum DurationUnit {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum SubscriptionPaymentStatus {
  PAYMENT_OK = 'PAYMENT_OK',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export interface SubscriptionPlanSettings {
  plan: SubscriptionPlan;
  /** discount in percents e.g. 10% = 10 */
  discount: number;
  minPortfolios: number;
  maxPortfolios: number;
  isTrialEnabled: boolean;
  trialDurationUnit?: DurationUnit | undefined;
  trialDuration?: number | undefined;
}

export interface PortfolioPriceSettings {
  portfolioId: string;
  tier: SubscriptionTier;
  /** price in currency units e.g. 2.99$ = 299 */
  price: number;
  isEnabled: boolean;
}

export enum BillingReason {
  SUBSCRIPTION_CREATE = 'SUBSCRIPTION_CREATE',
  SUBSCRIPTION_UPDATE = 'SUBSCRIPTION_UPDATE',
  SUBSCRIPTION_CYCLE = 'SUBSCRIPTION_CYCLE',
  UPCOMING = 'UPCOMING',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export enum InvoiceStatus {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  PAID = 'PAID',
  UNCOLLECTIBLE = 'UNCOLLECTIBLE',
  UNRECOGNIZED = 'UNRECOGNIZED'
}

export interface InvoiceItem {
  name: string;
  quantity: number;
  unitPrice: number;
  amount: number;
}

export interface Invoice {
  id: string;
  subscriptionId: string;
  customerId: string;
  subtotal: number;
  total: number;
  amountPaid: number;
  amountRemaining: number;
  attemptCount: number;
  attempted: boolean;
  billingReason: BillingReason;
  invoicePdf?: string | undefined;
  receiptPdf?: string | undefined;
  createdAtTimestamp: number;
  dueDateTimestamp: number;
  nextPaymentAttemptTimestamp?: number | undefined;
  status: InvoiceStatus;
  latestAttempt?: Transaction;
  items: InvoiceItem[];
  discountPercentage: number;
}

export const subscriptionToStatus = (subscription: Subscription) => {
  if (!subscription.isActive || subscription.unsubscribedAtTimestamp) {
    return `Отменена`;
  }
  if (subscription.suspendedUntilTimestamp && subscription.suspendedUntilTimestamp > Date.now()) {
    return `Приостановлена`;
  }
  if (subscription.latestInvoice?.status === InvoiceStatus.PAID) {
    return `Активна`;
  }
  if (
    subscription.latestInvoice?.status === InvoiceStatus.UNCOLLECTIBLE ||
    (subscription.latestInvoice?.status === InvoiceStatus.OPEN &&
      subscription.latestInvoice?.attempted)
  ) {
    return `paymentProblem`;
  }

  return `Активна`;
};

export const subscriptionPlanToText = (plan?: SubscriptionPlan) => {
  switch (plan) {
    case SubscriptionPlan.BASIC:
      return `Базовый`;
    case SubscriptionPlan.STANDARD:
      return `Стандартный`;
    case SubscriptionPlan.PREMIUM:
      return `Премиум`;
  }
};

export const subscriptionToTier = (subscription?: Subscription) => {
  switch (subscription?.tier) {
    case SubscriptionTier.MONTHLY:
      return `Месяц`;
    case SubscriptionTier.THREE_MONTHLY:
      return `Три месяца`;
    case SubscriptionTier.SIX_MONTHLY:
      return `Шесть месяцев`;
    case SubscriptionTier.YEARLY:
      return `Год`;
  }
};

export const subscriptionToNextRenewalDate = (subscription?: Subscription) => {
  const date = subscription && new Date(subscription.nextRenewalTimestamp);
  return date?.toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
};

export const subscriptionCanceled = (subscription: Subscription) => {
  const date = new Date(
    subscription.unsubscribedAtTimestamp ? subscription.unsubscribedAtTimestamp : 0
  );

  return date.toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
};

export const billingReasonToText = (billingReason: BillingReason) => {
  switch (billingReason) {
    case BillingReason.SUBSCRIPTION_CREATE:
      return `Создание подписки`;
    case BillingReason.SUBSCRIPTION_UPDATE:
      return `Обновление подписки`;
    case BillingReason.SUBSCRIPTION_CYCLE:
      return `Подписка`;
    case BillingReason.UPCOMING:
      return `Предстоящий платеж`;
  }
};
