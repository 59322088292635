import { ResultSet } from "@cubejs-client/core";
import { useDeepCompareMemo } from "use-deep-compare";

import { ChartRenderer, PivotConfig } from "components/Charts/ChartRenderer";
import NormalCell from "./Cells/NormalCell";
import Table from "./Table";
import DateTimeCell from "./Cells/DateTimeCell";

const stackedChartData = (resultSet: any) => {
  const data = resultSet.loadResponses[0].data
    .map((a: any) =>
      Object.fromEntries(
        Object.entries(a).map(([key, value]) => [
          key.split(".").slice(1).join("."),
          value,
        ])
      )
    )
    .map((a: any) => ({
      ...a,
      name: [a.firstname, a.surname].join(" "),
      devicePlatform: a.devicePlatform || "Приложение",
    }));
  return data;
};

const TableRenderer = (
  {
    resultSet,
    pivotConfig,
  }: {
    resultSet: ResultSet;
    pivotConfig: PivotConfig;
  },
) => {
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet),
    [resultSet.serialize()]
  );

  const columns = [
    {
      Header: "Дата входа",
      accessor: "createdat",
      Cell: DateTimeCell,
      sort: false,
    },
    {
      Header: "Страна",
      accessor: "locationCountrycode",
      width: 50,
      sort: false,
      Cell: NormalCell,
    },
    {
      Header: "Город",
      accessor: "locationCity",
      width: 50,
      sort: false,
      Cell: NormalCell,
    },
    {
      Header: "Адрес IP",
      accessor: "locationIp",
      sort: false,
      width: 50,
      Cell: NormalCell,
    },
    {
      Header: "Платформа",
      accessor: "devicePlatform",
      width: 50,
      sort: false,
      Cell: NormalCell,
    },
  ];

  return (
    <Table
      columns={columns}
      data={data}
    />
  );
};

export const UserSessionsTable = (userId: string) => {
  return (
    <ChartRenderer
      _hover={{
        cursor: 'default',
      }}
      minHeight={300}
      maxHeight={600}
      renderOnlyElement={false}
      isDraggable={false}
      flexGrow={0}
      chartProps={{
        id: "user_sessions_table",
        title: "Пользовательские сессии",
        query: {
          dimensions: [
            "Sessions.locationCountrycode",
            "Sessions.locationIp",
            "Sessions.locationCity",
            "Sessions.devicePlatform",
            "Sessions.userid",
            "Sessions.createdat",
            "Sessions.updatedat",
            "Sessions.expiresAtTimestamp",
          ],
          order: {
            "Sessions.createdat": "desc",
          },
          filters: [
            {
              member: "Sessions.userid",
              operator: "equals",
              values: [userId],
            }
          ],
          limit: 5,
        },
        pivotConfig: {
          x: [
            "Sessions.locationCountrycode",
            "Sessions.locationIp",
            "Sessions.locationCity",
            "Sessions.devicePlatform",
            "Sessions.userid",
            "Sessions.createdat",
            "Sessions.updatedat",
            "Sessions.expiresAtTimestamp",
          ],
          y: [],
          fillMissingDates: true,
          joinDateRange: false,
        },
        element: TableRenderer,
      }}
    />
  );
};
