import cubejs from "@cubejs-client/core";
import { CUBE_ENDPOINT } from "data/endpoints";
import { useMemo } from "react";
import { useAuth } from "state/AuthContext";

export const useCube = () => {
  const { loggedIn, token, logOut } = useAuth();

  return useMemo(() => {
    if (!loggedIn || !token) {
      logOut();
      return;
    }

    return cubejs(token, { apiUrl: CUBE_ENDPOINT });
  }, [token]);
};
