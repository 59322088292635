import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  DragStartEvent,
  DragEndEvent,
  closestCenter,
  MeasuringConfiguration,
  MeasuringStrategy,
  DragOverlay,
  DropAnimation,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  arrayMove,
} from "@dnd-kit/sortable";

import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { CSS } from "@dnd-kit/utilities";

import { Box, Flex } from "@chakra-ui/react";

import AppLayout from "layouts/AppLayout";

import { ActiveSubscriptionsByPortfolio } from "components/Charts/Subscriptions/ActiveSubscriptionsByPortfolio";
import { ActiveSubscriptionsByType } from "components/Charts/Subscriptions/ActiveSubscriptionsByType";
import { NewUsersLast30Days } from "components/Charts/InlineCharts/NewUsersLast30Days";
import { NewSubscriptionsLast30Days as InlineNewSubscriptionsLast30Days } from "components/Charts/InlineCharts/NewSubscriptionsLast30Days";
import { NewSessionsLast30Days } from "components/Charts/InlineCharts/NewSessionsLast30Days";
import { NewReferralsLast30Days } from "components/Charts/InlineCharts/NewReferralsLast30Days";
import { SessionsByCountryCode } from "components/Charts/Sessions/SessionsByCountryCode";
import { NewSubscriptionsLast30Days } from "components/Charts/Subscriptions/NewSubscriptionsLast30Days";
import { ActiveSessionsByPlatform } from "components/Charts/Sessions/ActiveSessionsByPlatform";
import { useState } from "react";

const measuring: MeasuringConfiguration = {
  droppable: {
    strategy: MeasuringStrategy.BeforeDragging,
  },
};

const dropAnimation: DropAnimation = {
  keyframes({ transform }) {
    return [
      { transform: CSS.Transform.toString(transform.initial) },
      {
        transform: CSS.Transform.toString({
          scaleX: 0.98,
          scaleY: 0.98,
          x: transform.final.x - 10,
          y: transform.final.y - 10,
        }),
      },
    ];
  },
};

const DashboardItems = {
  ActiveSubscriptionsByType: ActiveSubscriptionsByType,
  ActiveSubscriptionsByPortfolio: ActiveSubscriptionsByPortfolio,
  ActiveSessionsByPlatform: ActiveSessionsByPlatform,
  SessionsByCountryCode: SessionsByCountryCode,
  NewSubscriptionsLast30Days: NewSubscriptionsLast30Days,
};

export const Dashboard = () => {
  const [activeId, setActiveId] = useState<string | number | null>(null);
  const [items, setItems] = useState(() =>
    (localStorage.getItem("dashboardItems")
      ? JSON.parse(localStorage.getItem("dashboardItems") || "[]")
      : Object.keys(DashboardItems)
    ) as (keyof typeof DashboardItems)[]
  );
  const activeIndex = activeId ? items.findIndex((a) => DashboardItems[a].id === activeId) : -1;
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  function handleDragStart({ active }: DragStartEvent) {
    setActiveId(active.id);
  }

  function handleDragCancel() {
    setActiveId(null);
  }

  function handleDragEnd({ over }: DragEndEvent) {
    if (over) {
      const overIndex = items.findIndex((a) => DashboardItems[a].id === over.id);

      if (activeIndex !== overIndex) {
        const newIndex = overIndex;

        setItems((items) => arrayMove(items, activeIndex, newIndex));
        localStorage.setItem("dashboardItems", JSON.stringify(arrayMove(items, activeIndex, newIndex)));
      }
    }

    setActiveId(null);
  }

  const activeItem = activeId ? items.find((a) => DashboardItems[a].id === activeId) : undefined;

  return (
    <AppLayout>
      <Flex
        ml={"-60px"}
        mr={"-45px"}
        flexGrow={1}
        overflow="scroll"
        padding={2}
      >
        <Flex
          width={"fit-content"}
          flexDirection={"row"}
          px={"auto"}
          alignItems="center"
          gap={4}
        >
          <Box w={"35px"} />
          <NewUsersLast30Days />
          <InlineNewSubscriptionsLast30Days />
          <NewSessionsLast30Days />
          <NewReferralsLast30Days />
        </Flex>
      </Flex>
      <Box p={2} />
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragCancel={handleDragCancel}
        sensors={sensors}
        collisionDetection={closestCenter}
        measuring={measuring}
      >
        <SortableContext strategy={() => null} items={items.map((a) => DashboardItems[a].id)}>
          <Flex gap={4} width="100%" flexGrow={1} flexWrap={"wrap"}>
            {items.map((a) =>
              DashboardItems[a].id === activeId ? (
                <Box opacity={0.5}>{DashboardItems[a].element()}</Box>
              ) : (
                DashboardItems[a].element()
              )
            )}
          </Flex>
        </SortableContext>
        <DragOverlay
          dropAnimation={dropAnimation}
          modifiers={[restrictToWindowEdges]}
          adjustScale={false}
        >
          {activeItem ? DashboardItems[activeItem].element() : null}
        </DragOverlay>
      </DndContext>
    </AppLayout>
  );
};
