import './style.scss';
import { Box, Flex, Icon, keyframes, Text } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import PortfolioIcon from 'icons/PortfolioIcon';
import {
  BondsYieldPercentage,
  PortfolioAsset,
  Portfolio_Risk,
  Portfolio_YieldPercentage,
  riskToText,
  SubscriptionPlan,
  SubscriptionTier
} from 'utils/Subscription';

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)!;
  return {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  };
}

export interface PortfolioCardProps {
  id: string;
  name: string;
  color: string;
  risk: Portfolio_Risk;
  assets: PortfolioAsset[];
  cashPercentage: number;
  yieldPercentage?: Portfolio_YieldPercentage;
  bondsYieldPercentage?: BondsYieldPercentage;
  grayscale?: boolean;
  subscriptionId?: string;
  subscriptionPlan?: SubscriptionPlan;
  subscriptionTier?: SubscriptionTier;
  portfolioIds?: string[];
  editable?: boolean;
  bonds?: any[];
  portfoliosQuantity?: number;
  canceled?: boolean;
  onClick?: () => void;
}

const gradient = keyframes`
  0% {
    background-position: 50% 50%;
  }
  25% {
    background-position: 0% 0%;
  }
  75% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 50% 50%;
  }
`;

const PortfolioCard = (props: PortfolioCardProps) => {
  const { editable, canceled } = props;

  const colorRgb = hexToRgb(props.color);
  const yieldPercentage = props.yieldPercentage;
  const bondsYieldPercentage = props.bondsYieldPercentage;
  const cashPercentage = props.cashPercentage;
  const yieldPercentageTotal = yieldPercentage?.total;
  const yieldPercentageYear = yieldPercentage?.year;
  const yieldPercentageDividendYear = yieldPercentage?.dividendYear;
  const bondsYieldPercentageTotal = bondsYieldPercentage?.bondsTotal;
  const bondsYieldPercentageYear = bondsYieldPercentage?.bondsYieldYear;
  const averageBondsYieldYear = bondsYieldPercentage?.averageBondsYieldYear;

  return (
    <Flex
      className="product-card"
      animation={props.grayscale ? undefined : `${gradient} 8s ease infinite`}
      backgroundImage={`linear-gradient(
          130deg,
          rgba(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}, 0.48),
          rgba(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}, 0.09),
          rgba(${colorRgb.r}, ${colorRgb.g}, ${colorRgb.b}, 0.57)
        )`}
      backgroundSize="200% 200%"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      p={`${editable ? '10px 25px 25px 25px' : '25px'}`}
      borderRadius={'16px'}
      flexGrow={1}
      filter={props.grayscale ? 'grayscale(100%)' : 'none'}
      onClick={props.onClick}>
      <Flex
        width="100%"
        flexDirection={'column'}
        justifyContent={'space-between'}
        opacity={canceled ? 0.5 : 1}>
        {editable ? (
          <Flex alignItems="center" justifyContent="space-between">
            {canceled ? (
              <Flex alignItems="center">
                <strong>отменен</strong>
              </Flex>
            ) : (
              <>
                <Flex alignItems="center">
                  <CheckCircleIcon
                    mr="5px"
                    backgroundColor="#fff"
                    color="#1dd81d"
                    borderRadius="50%"
                  />
                  <strong>активен</strong>
                </Flex>
              </>
            )}
          </Flex>
        ) : null}
        <Flex alignItems="center">
          <Icon as={PortfolioIcon} portfolioColor={'#3C3C434D'} />
          <Box p={2} />
          <Text fontSize="md" fontWeight="bold">
            {props.name}
          </Text>
        </Flex>
        <Flex flexDirection={'column'}>
          <Flex flexDirection={'row'} gap={8}>
            <Flex flexDirection={'column'}>
              <Text fontSize="sm" fontWeight="regular">
                денежные средства
              </Text>
              <Box p={0.5} />
              <Text fontSize="sm" fontWeight="bold">
                {(cashPercentage * 100).toFixed(2)}%
              </Text>
            </Flex>
            <Flex flexDirection={'column'}>
              <Text fontSize="sm" fontWeight="regular">
                риск
              </Text>
              <Box p={0.5} />
              <Text fontSize="sm" fontWeight="bold">
                {riskToText(props.risk)}
              </Text>
            </Flex>
          </Flex>
          <Box p={2} />
          <Flex className="portfolio-block__bottom" flexDirection={'row'} gap={8}>
            <Flex flexDirection={'column'} minWidth="75px">
              <Text fontSize="sm" fontWeight="regular">
                все время
              </Text>
              <Box p={0.5} />
              <Text fontSize="sm" fontWeight="bold">
                {yieldPercentageTotal
                  ? `${Number((yieldPercentageTotal * 100).toFixed(2)) > 0 ? '+' : ''}
                  ${(yieldPercentageTotal * 100).toFixed(2)}%`
                  : bondsYieldPercentageTotal
                  ? `${Number((bondsYieldPercentageTotal * 100).toFixed(2)) > 0 ? '+' : ''}
                  ${(bondsYieldPercentageTotal * 100).toFixed(2)}%`
                  : '0.00%'}
              </Text>
            </Flex>
            <Flex flexDirection={'column'}>
              <Text fontSize="sm" fontWeight="regular">
                %годовых
              </Text>
              <Box p={0.5} />
              <Text fontSize="sm" fontWeight="bold">
                {yieldPercentageYear
                  ? `${Number((yieldPercentageYear * 100).toFixed(2)) > 0 ? '+' : ''}
                  ${(yieldPercentageYear * 100).toFixed(2)}%`
                  : bondsYieldPercentageYear
                  ? `${bondsYieldPercentageYear > 0 ? '+' : ''}${(
                      bondsYieldPercentageYear * 100
                    ).toFixed(2)}%`
                  : '0.00%'}
              </Text>
            </Flex>
            <Flex flexDirection={'column'}>
              <Text fontSize="sm" fontWeight="regular">
                {bondsYieldPercentageTotal ? `%среднегодовых` : `месяц`}
              </Text>
              <Box p={0.5} />
              <Text fontSize="sm" fontWeight="bold">
                {yieldPercentageDividendYear
                  ? `${Number((yieldPercentageDividendYear * 100).toFixed(2)) > 0 ? '+' : ''}${(
                      yieldPercentageDividendYear * 100
                    ).toFixed(2)}%`
                  : averageBondsYieldYear
                  ? `${Number((averageBondsYieldYear * 100).toFixed(2)) > 0 ? '+' : ''}${(
                      averageBondsYieldYear * 100
                    ).toFixed(2)}%`
                  : '0.00%'}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PortfolioCard;
