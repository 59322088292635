export const baseURL = process.env.REACT_APP_API_URL || 'https://api.crm.bidkogan.caitan.me/api/v1';
export const CUBE_ENDPOINT =
  process.env.REACT_APP_CUBE_URL || 'https://cube.bidkogan.caitan.me/cubejs-api/v1';

// auth
export const AUTH_ENDPOINT = `${baseURL}/auth/login`;

export const USER_ITERATOR_CREATE = `${baseURL}/users/iterator`;
export const USER_ITERATOR_NEXT = (iteratorId: string) =>
  `${baseURL}/users/iterator/${iteratorId}/next`;
export const USER_BY_ID = (id: string) => `${baseURL}/users/${id}`;
export const INVOICES_BY_ID = (id: string) => `${baseURL}/subscriptions/user-invoices/${id}`;
export const SUBSCRIPTIONS_TIERS = `${baseURL}/subscriptions/tiers`;
export const SUBSCRIPTIONS = `${baseURL}/subscriptions`;
export const QUERY_USERS = `${baseURL}/users/query-users`;
