import { Flex, Text } from '@chakra-ui/react';

import { CellItem } from '.';
import dateToTime from 'utils/dateToTime';
import formatDate from 'utils/formatDate';

const DateTimeCell = (props: CellItem) => {
  const { value } = props;

  const dt = new Date(value);

  return (
    <Flex my='auto'>
      <Text my='auto' fontWeight={500} fontSize={12} lineHeight="100%" marginBottom={1}>
        {dateToTime(dt, 'ru')}
      </Text>
      &nbsp;
      <Text my='auto' fontWeight={500} fontSize={12} lineHeight="100%" whiteSpace="nowrap">
        {formatDate(dt, 'short', 'ru')}
      </Text>
    </Flex>
  );
};

export default DateTimeCell;
