import { Dispatch, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Spinner,
  Text,
  useColorModeValue
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import ImageCell from './Cells/ImageCell';
import NormalCell from './Cells/NormalCell';
import Table from './Table';
import ViewCell from './Cells/ViewCell';
import { User, UsersIteratorFilter } from 'data/User';
import { useQuery } from 'react-query';
import { getQueryUsers } from 'repository/users/getQueryUsers';
import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import CheckCell from './Cells/CheckCell';
import {
  subscriptionPlanToText,
  subscriptionToNextRenewalDate,
  subscriptionToTier
} from 'utils/Subscription';
import DateCell from './Cells/DateCell';
import { useUsersCtx } from 'state/UsersContext';

const TableRenderer = (
  data: User[],
  isLoading: boolean,
  setData: Dispatch<React.SetStateAction<User[]>>,
  refetch: any
) => {
  const navigate = useNavigate();

  const columns = [
    {
      Header: '',
      accessor: 'avatar.sm',
      Cell: ImageCell,
      sort: false,
      maxWidth: 70
    },
    // {
    //   Header: 'Имя пользователя',
    //   accessor: 'username',
    //   sort: false,
    //   Cell: NormalCell
    // },
    {
      Header: 'Имя',
      accessor: 'name',
      sort: false,
      Cell: NormalCell
    },
    {
      Header: 'Почта',
      accessor: 'emailAddress',
      sort: false,
      Cell: NormalCell
    },
    {
      Header: 'Номер телефона',
      accessor: 'phoneNumber',
      sort: false,
      Cell: NormalCell
    },
    {
      Header: 'Платник',
      accessor: 'subscriptionActive',
      Cell: CheckCell,
      width: 'max-content',
      sort: true
    },
    {
      Header: 'Пакет',
      accessor: 'plan',
      Cell: NormalCell,
      width: 'max-content',
      sort: true
    },
    {
      Header: 'Длительность',
      accessor: 'tier',
      Cell: NormalCell,
      width: 'max-content',
      sort: true
    },
    {
      Header: 'Дата завершения',
      accessor: 'endDate',
      Cell: DateCell,
      width: 'max-content',
      sort: true
    },
    {
      Header: 'Регион',
      accessor: 'location.region',
      sort: false,
      Cell: NormalCell
    },
    {
      Header: '',
      accessor: 'id',
      Cell: ViewCell,
      width: 10,
      sort: false
    }
  ];

  return isLoading ? (
    <Flex justifyContent="center" alignItems="center">
      <Spinner />
    </Flex>
  ) : data ? (
    <>
      <Table
        columns={columns}
        data={data.map((u) => ({
          ...u,
          name:
            u.firstName && u.surname
              ? `${u.firstName} ${u.surname}`
              : u.firstName || u.surname || '-',
          subscriptionActive: u.subscriptions?.find((item) => item.isActive)
            ? 'active'
            : 'disabled',
          plan: u.subscriptions?.find((item) => item.isActive)
            ? subscriptionPlanToText(u.subscriptions?.find((item) => item.isActive)?.plan)
            : '-',
          tier: u.subscriptions?.find((item) => item.isActive)
            ? subscriptionToTier(u.subscriptions?.find((item) => item.isActive))
            : '-',
          endDate: u.subscriptions?.find((item) => item.isActive)
            ? subscriptionToNextRenewalDate(u.subscriptions?.find((item) => item.isActive))
            : '-'
        }))}
        setData={setData}
        refetch={refetch}
        onElementClick={(el) => navigate(`/users/${el.id}`)}
      />
    </>
  ) : (
    <></>
  );
};

export const UsersTable = () => {
  const { currPage, setCurrPage } = useUsersCtx();
  const cardBg = useColorModeValue('white', 'navy.800');
  const [data, setData] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isSearch, setIsSearch] = useState(false);
  const [filter, setFilter] = useState<UsersIteratorFilter | undefined>(undefined);
  const [isReset, setIsReset] = useState<boolean>(false);
  const { refetch } = useQuery(['users-query'], () => getQueryUsers(currPage, filter), {
    enabled: false
  });
  const [emailValue, setEmailValue] = useState<string>('');
  const [phoneNumberValue, setPhoneNumberValue] = useState<string>('');
  const [nameValue, setNameValue] = useState<string>('');
  const [selectSubscriptionPlanValue, setSelectSubscriptionPlanValue] = useState<string>('');
  const [selectSubscriptionTierValue, setSelectSubscriptionTierValue] = useState<string>('');
  const [withSubscriptionsValue, setWithSubscriptionsValue] = useState<string>('');

  const selectSubscriptionPlan = [
    {
      title: 'Нет',
      value: ''
    },
    {
      title: 'Базовый',
      value: 'BASIC'
    },
    {
      title: 'Стандартный',
      value: 'STANDARD'
    },
    {
      title: 'Премиум',
      value: 'PREMIUM'
    }
  ];

  const selectSubscriptionTier = [
    {
      title: 'Нет',
      value: ''
    },
    {
      title: 'Месяц',
      value: 'MONTHLY'
    },
    {
      title: 'Три месяца',
      value: 'THREE_MONTHLY'
    },
    {
      title: 'Шесть месяцев',
      value: 'SIX_MONTHLY'
    },
    {
      title: 'Год',
      value: 'YEARLY'
    }
  ];

  const withSubscriptions = [
    {
      title: 'Все',
      value: ''
    },
    {
      title: 'С активная подпиской',
      value: 'active'
    }
  ];

  const inputBg = useColorModeValue('white', 'navy.800');

  const onSearch = () => {
    setCurrPage?.(1);
    setIsLoading(true);
    setIsSearch(true);
  };

  const filterReset = () => {
    setEmailValue('');
    setPhoneNumberValue('');
    setNameValue('');
    setSelectSubscriptionPlanValue('');
    setSelectSubscriptionTierValue('');
    setWithSubscriptionsValue('');
    setFilter(undefined);
    setIsReset(true);
    setCurrPage?.(1);
  };

  useEffect(() => {
    if (isSearch) {
      setIsSearch(false);
      refetch().then((resp) => {
        if (resp.data?.data) {
          setData(resp.data.data.users);
          setIsLoading(false);
          setCurrPage?.(2);
        } else {
          setData([]);
          setIsLoading(false);
        }
      });
    }
  }, [isSearch]);

  useEffect(() => {
    if (isReset && !selectSubscriptionPlanValue && !selectSubscriptionTierValue) {
      onSearch();
      setIsReset(false);
    }
  }, [isReset, selectSubscriptionPlanValue, selectSubscriptionTierValue]);

  useEffect(() => {
    refetch().then((resp) => {
      if (resp.data?.ok && resp.data.data) {
        setIsLoading(false);
        setData(resp.data.data.users);
        setCurrPage?.(2);
      }
    });
  }, []);

  return (
    <Flex
      flexDirection={'column'}
      justifyContent={'space-between'}
      px={'20px'}
      py={'16px'}
      width={'100%'}
      height={'100%'}
      borderRadius={'8px'}
      boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
      bg={cardBg}>
      <Text fontSize={'xl'} fontWeight={'bold'} mb={4}>
        Пользователи системы
      </Text>
      <Flex mb={4} gap={4} justifyContent={'flex-start'}>
        <Flex flexDirection="column">
          <Flex alignItems="center">
            <Input
              m="5px"
              variant="search"
              fontSize="sm"
              bg={inputBg}
              borderRadius="8px"
              maxW={'270px'}
              border={'0.5px solid'}
              value={phoneNumberValue}
              onKeyUp={(e) => e.key === 'Enter' && onSearch()}
              onChange={(e) => {
                setFilter({ ...filter, phoneNumber: e.currentTarget.value });
                setPhoneNumberValue(e.currentTarget.value);
              }}
              placeholder="Номер телефона"
            />
            <Input
              m="5px"
              variant="search"
              fontSize="sm"
              bg={inputBg}
              borderRadius="8px"
              maxW={'270px'}
              border={'0.5px solid'}
              value={emailValue}
              onKeyUp={(e) => e.key === 'Enter' && onSearch()}
              onChange={(e) => {
                setFilter({ ...filter, email: e.currentTarget.value });
                setEmailValue(e.currentTarget.value);
              }}
              placeholder="Почта"
            />
            <Input
              m="5px"
              variant="search"
              fontSize="sm"
              bg={inputBg}
              borderRadius="8px"
              maxW={'270px'}
              border={'0.5px solid'}
              value={nameValue}
              onKeyUp={(e) => e.key === 'Enter' && onSearch()}
              onChange={(e) => {
                setFilter({ ...filter, name: e.currentTarget.value });
                setNameValue(e.currentTarget.value);
              }}
              placeholder="Имя или фамилия"
            />
          </Flex>
          <Flex alignItems="center">
            <FormControl m="5px">
              <FormLabel>C активной подпиской: </FormLabel>
              <Select
                value={withSubscriptionsValue}
                onChange={(e) => {
                  setFilter({
                    ...filter,
                    withSubscriptions: e.currentTarget.value === 'active' ? true : false
                  });
                  setWithSubscriptionsValue(e.currentTarget.value);
                }}>
                {withSubscriptions.map((item) => (
                  <option value={item.value}>{item.title}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl m="5px">
              <FormLabel>Пакет:</FormLabel>
              <Select
                value={selectSubscriptionPlanValue ? selectSubscriptionPlanValue : ''}
                onChange={(e) => {
                  setFilter({ ...filter, subscriptionPlan: e.currentTarget.value });
                  setSelectSubscriptionPlanValue(e.currentTarget.value);
                }}>
                {selectSubscriptionPlan.map((item) => (
                  <option value={item.value}>{item.title}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl m="5px">
              <FormLabel>Длительность: </FormLabel>
              <Select
                value={selectSubscriptionTierValue ? selectSubscriptionTierValue : ''}
                onChange={(e) => {
                  setFilter({ ...filter, subscriptionTier: e.currentTarget.value });
                  setSelectSubscriptionTierValue(e.currentTarget.value);
                }}>
                {selectSubscriptionTier.map((item) => (
                  <option value={item.value}>{item.title}</option>
                ))}
              </Select>
            </FormControl>
          </Flex>
        </Flex>
        <Flex flexDirection="column">
          <Button minWidth="auto" m="5px" onClick={onSearch}>
            <SearchIcon />
            <Box ml="15px">Применить фильтры</Box>
          </Button>
          <Button minWidth="auto" m="5px" onClick={filterReset}>
            <CloseIcon />
            <Box ml="15px">Очистить фильтры</Box>
          </Button>
        </Flex>
      </Flex>
      {TableRenderer(data, isLoading, setData, refetch)}
    </Flex>
  );
};
