import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';

export enum Modal {
  NO_MODAL = 'NO_MODAL'
}

type RemoveModalPayload = BasicPayload | undefined;
type ModalsContextType = {
  open: (m: Modal, p?: RemoveModalPayload) => void;
  close: (m: Modal) => void;
  closeAll: VoidFunction;
  isVisible: (m: Modal) => boolean;
};

export const ModalsContext = createContext<ModalsContextType>({
  open: () => null,
  close: () => null,
  closeAll: () => null,
  isVisible: () => false
});

const { Provider } = ModalsContext;

const ModalsContextProvider = (props: PropsWithChildren<{}>) => {
  const [modals, setModals] = useState<Modal[]>([]);
  const [payloads, setPayloads] = useState<{ [k: string]: RemoveModalPayload }>({});

  const open = useCallback(
    (m: Modal, payload?: RemoveModalPayload) => {
      setModals([...modals, m]);
      if (payload) {
        setPayloads((p) => ({ ...p, [m]: payload }));
      }
    },
    [setModals, setPayloads, modals]
  );

  const close = useCallback(
    (m: Modal) => {
      setModals(modals.filter((modal) => modal !== m));
      setPayloads((p) => ({ ...p, [m]: undefined }));
    },
    [setModals, modals]
  );

  const closeAll = () => setModals([]);

  const getVisibility = (m: Modal): boolean => modals.includes(m);
  const getModalProps = (modal: Modal) => ({
    isVisible: getVisibility(modal),
    payload: payloads[modal]
  });

  const value = useMemo(() => ({ open, close, closeAll, isVisible: getVisibility }), [close, open]);

  return <Provider value={value}>{props.children}</Provider>;
};

export default ModalsContextProvider;

export function useModals() {
  return useContext(ModalsContext);
}
