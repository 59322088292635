export default function dateToTime(d: Date | string, loc = 'en') {
  let dt: Date;
  if (typeof d === 'string') {
    dt = new Date(d);
  } else {
    dt = d;
  }
  return dt.toLocaleTimeString(loc || 'en', {
    timeStyle: 'short',
    hour12: false,
  });
}
