import { ResultSet } from "@cubejs-client/core";

import { ChartRenderer, PivotConfig } from "components/Charts/ChartRenderer";

import { useDeepCompareMemo } from "use-deep-compare";
import { Chart, Axis, Tooltip, Geom } from "bizcharts";
import { ChartCategory, Chart as IChart } from "../interfaces/Chart";

const stackedChartData = (resultSet: any) => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }: any) =>
      yValuesArray.map(([yValues, m]: any) => ({
        x: resultSet.axisValuesString(xValues, ", ").split("T")[0],
        color: resultSet.axisValuesString(yValues, ", ").split(",")[0],
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a: any, b: any) => a.concat(b), []);
  return data;
};

const BarChartRenderer = ({
  resultSet,
  pivotConfig,
}: {
  resultSet: ResultSet;
  pivotConfig: PivotConfig;
}) => {
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet),
    [resultSet.serialize()]
  );
  const stacked = !(pivotConfig.x || []).includes("measures");

  return (
    <Chart
      scale={{
        x: {
          tickCount: 8,
        },
      }}
      autoFit
      height={400}
      data={data}
      forceFit
    >
      <Axis name="x" />
      <Axis name="measure" />
      <Tooltip />
      <Geom
        type="interval"
        position="x*measure"
        color="color"
        adjust={stacked ? "stack" : "dodge"}
      />
    </Chart>
  );
};

export const SessionsByCountryCode: IChart = {
  id: "sessions_by_country_code",
  name: "Сессии по странам",
  category: ChartCategory.SESSIONS,
  element: () => {
    return (
      <ChartRenderer
        width={700}
        height={400}
        chartProps={{
          id: "sessions_by_country_code",
          title: "Сессии по странам",
          query: {
            measures: ["Sessions.count"],
            order: {
              "Sessions.count": "desc",
            },
            dimensions: ["Sessions.locationCountrycode"],
            timeDimensions: [
              {
                dimension: "Sessions.createdat",
                granularity: "day",
                dateRange: "Last 30 days",
              },
            ],
          },
          pivotConfig: {
            x: ["Sessions.createdat.day"],
            y: ["Sessions.locationCountrycode", "measures"],
            fillMissingDates: true,
            joinDateRange: false,
          },
          element: BarChartRenderer,
        }}
      />
    );
  },
};
