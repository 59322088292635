import { ResultSet } from '@cubejs-client/core';

import { ChartRenderer } from 'components/Charts/ChartRenderer';

import { useDeepCompareMemo } from 'use-deep-compare';
import { PieChart } from 'bizcharts';
import { Chart, ChartCategory } from '../interfaces/Chart';

const PieChartRenderer = ({ resultSet }: { resultSet: ResultSet }) => {
  const [data, angleField] = useDeepCompareMemo(() => {
    return [resultSet.chartPivot(), resultSet.series()];
  }, [resultSet]);

  return (
    <PieChart
      data={data}
      radius={0.8}
      angleField={angleField[0]?.key || 'undefined'}
      colorField="x"
      label={{
        visible: true,
        offset: 20
      }}
      legend={{
        // offsetY: -30,
        visible: true,
        position: 'bottom'
      }}
    />
  );
};

export const ActiveSubscriptionsByType: Chart = {
  id: 'active_subscriptions_by_type',
  name: 'Active Subscriptions by Type',
  category: ChartCategory.SESSIONS,
  element: () => {
    return (
      <ChartRenderer
        width={320}
        height={400}
        chartProps={{
          id: 'active_subscriptions_by_type',
          title: 'Активные подписки по типам',
          query: {
            measures: ['Subscriptions.count'],
            order: {
              'Subscriptions.count': 'desc'
            },
            dimensions: ['Subscriptions.plan'],
            filters: [
              {
                member: 'Subscriptions.currentPeriodExpiresAtTimestamp',
                operator: 'gt',
                values: [Date.now().toString()]
              }
            ]
          },
          pivotConfig: {
            x: ['Subscriptions.plan'],
            y: ['measures'],
            fillMissingDates: true,
            joinDateRange: false
          },
          element: PieChartRenderer
        }}
      />
    );
  }
};
