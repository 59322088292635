import { ResultSet } from "@cubejs-client/core";

import { ChartRenderer } from "components/Charts/ChartRenderer";

import { useDeepCompareMemo } from "use-deep-compare";
import { Chart, Axis, Tooltip, Geom } from "bizcharts";
import { ChartCategory, Chart as IChart } from "../interfaces/Chart";

const stackedChartData = (resultSet: any) => {
  const data = resultSet
    .pivot()
    .map(({ xValues, yValuesArray }: any) =>
      yValuesArray.map(([yValues, m]: any) => ({
        x: resultSet.axisValuesString(xValues, ", ").split("T")[0],
        color: resultSet.axisValuesString(yValues, ", ").split(",")[0],
        measure: m && Number.parseFloat(m),
      }))
    )
    .reduce((a: any, b: any) => a.concat(b), []);
  return data;
};

const LineChartRenderer = ({ resultSet }: { resultSet: ResultSet }) => {
  const data = useDeepCompareMemo(
    () => stackedChartData(resultSet),
    [resultSet]
  );
  return (
    <Chart
      scale={{
        x: {
          tickCount: 8,
        },
      }}
      autoFit
      height={300}
      data={data}
      forceFit
    >
      <Axis name="x" />
      <Axis name="measure" />
      <Tooltip
        crosshairs={{
          type: "y",
        }}
      />
      <Geom type="line" position="x*measure" size={2} color="color" />
    </Chart>
  );
};

export const NewSubscriptionsLast30Days: IChart = {
  id: "new_subscriptions_last_30_days",
  name: "New Subscriptions Last 30 Days",
  category: ChartCategory.SESSIONS,
  element: () => {
    return (
      <ChartRenderer
        width={700}
        height={400}
        chartProps={{
          id: "new_subscriptions_last_30_days",
          title: "Новые подписки за последние 30 дней",
          query: {
            measures: ["Subscriptions.count"],
            timeDimensions: [
              {
                dimension: "Subscriptions.createdat",
                granularity: "day",
                dateRange: "Last 30 days",
              },
            ],
          },
          pivotConfig: {
            x: ["Subscriptions.createdat.day"],
            y: ["measures"],
            fillMissingDates: true,
            joinDateRange: false,
          },
          element: LineChartRenderer,
        }}
      />
    );
  },
};
