import { Box } from '@chakra-ui/react';

import LoginForm from './components/LoginForm';
import './styles.scss';

const LoginPage = () => (
  <div className="login-page">
    <Box p={2.5} />
    <LoginForm />
    <Box p={10} />
  </div>
);

export default LoginPage;
