import { GlobeIcon } from "components/Icons/Icons";
import { getNowMinusDays } from "utils/dateUtils";
import { InlineChartRenderer } from "./InlineChartRenderer";

export const NewSessionsLast30Days = () => {
  const beforeMonthFrom = getNowMinusDays(60);

  return (
    <InlineChartRenderer
      chartProps={{
        id: 'new_sessions_last_30_days',
        title: "Уникальные сессии",
        icon: <GlobeIcon />,
        percentageChangeText: "Изменение за месяц",
        query: {
          "measures": [
            "Sessions.count"
          ],
          "filters": [
            {
              "member": "Sessions.createdat",
              "operator": "afterDate",
              "values": [
                beforeMonthFrom.toISOString(),
              ]
            }
          ],
          "timeDimensions": [
            {
              "dimension": "Sessions.createdat",
              "granularity": "month"
            }
          ]
        },
        pivotConfig: {
          "x": [
            "Sessions.createdat.month"
          ],
          "y": [
            "measures"
          ],
          "fillMissingDates": true,
          "joinDateRange": false
        },
        valueProvider: ({resultSet, pivotConfig}) => {
          const length = resultSet.series(pivotConfig)[0]?.series.length ?? 0;
          const currentMonth: number = length < 1 ? 0 : (resultSet.series(pivotConfig)[0]?.series[length - 1]?.value ?? 0);
          const previousMonth: number = length < 2 ? 0 : (resultSet.series(pivotConfig)[0]?.series[length - 2]?.value ?? 0);

          return {
            value: currentMonth,
            previousValue: previousMonth,
            percentageChange: previousMonth === 0 ? (currentMonth === 0 ? 0 : 1) : (currentMonth / previousMonth - 1),
          }
        },
      }}
    />
  );
};