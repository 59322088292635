import { AUTH_ENDPOINT } from "data/endpoints";
import { postRequest } from "utils/baseRequest";

export const login = async (creds: { password: string }) => {
  try {
    const response = await postRequest<{ access_token: string }>(
      AUTH_ENDPOINT,
      { password: creds.password },
      true,
      true
    );

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false };
  }
};
