import {
  Flex,
  Skeleton,
  useColorModeValue,
  Image,
  Text,
  Box,
  Button,
  Center,
  Divider,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BsBriefcaseFill } from 'react-icons/bs';
import AppLayout from 'layouts/AppLayout';
import { UserSessionsTable } from 'components/Tables/UserSessionsTable';
import { useMutation, useQuery } from 'react-query';
import { banUser, getUserById, unbanUser } from 'repository/users';
import { User, User_Gender } from 'data/User';
import { Subscription } from 'data/Subscription';
import { getCurrentSubscriptionByUserId } from 'repository/subscriptions';
import { Portfolio } from 'data/Portfolio';
import { Icon } from '@chakra-ui/icons';
import UserSubscription from './UserSubscription';
import UserInvoices from './UserInvoices';

const PortfolioInSubscriptionCard = ({
  portfolio,
  price
}: {
  portfolio: Portfolio;
  price?: number;
}) => {
  return (
    <Flex
      flexDirection={'row'}
      width={'100%'}
      alignItems={'baseline'}
      justifyContent={'space-between'}>
      <Flex flexDirection={'row'} alignItems={'baseline'}>
        <Box m={'auto'} mr={4}>
          <Icon
            as={BsBriefcaseFill}
            textAlign={'center'}
            verticalAlign={'middle'}
            fontSize={'2xl'}
            color={'#' + portfolio.color}
          />
        </Box>
        <Flex flexDirection={'column'}>
          <Text fontSize={'sm'} fontWeight={'bold'}>
            {portfolio.name}
          </Text>
          <Text fontSize={'xs'} fontWeight={'normal'}>
            портфель
          </Text>
        </Flex>
      </Flex>
      <Flex height={'fit-content'} my={'auto'}>
        <Text fontSize={'sm'} fontWeight={'400'} alignItems={'flex-end'}>
          {((price ?? 0) / 100).toFixed(2)} руб.
        </Text>
      </Flex>
    </Flex>
  );
};

const UserCardCurrentSubscription = ({ id }: { id: string }) => {
  const [subscription, setSubscription] = useState<Subscription | null>(null);
  const { isSuccess: isSubscriptionSuccess, refetch: refetchSubscription } = useQuery(
    ['subscription'],
    () => getCurrentSubscriptionByUserId(id)
  );

  useEffect(() => {
    refetchSubscription().then((data) => {
      if (data.data?.data) {
        setSubscription(data.data.data);
      }
    });
  }, [id]);

  const cardBg = useColorModeValue('white', 'navy.800');

  return (
    <Flex
      borderRadius={'8px'}
      boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
      bg={cardBg}
      height={'fit-content'}
      flexGrow={1}>
      {!isSubscriptionSuccess ? (
        <Skeleton
          width={'100%'}
          height={'400px'}
          speed={2}
          opacity={0.9}
          borderRadius={'8px'}
          boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
        />
      ) : subscription ? (
        <Flex flexDirection={'column'} width={'100%'} py={4} px={8}>
          <Text fontSize={'xl'} fontWeight={'bold'} mb={4}>
            Текущая подписка{' '}
            {subscription.trialPeriodExpiresAtTimestamp &&
            subscription.trialPeriodExpiresAtTimestamp > Date.now()
              ? '(пробный период)'
              : ''}
          </Text>
          <Flex flexDirection={'column'} height={'100%'} justifyContent={'space-between'}>
            <Flex flexDirection={'row'} justifyContent={'space-between'}>
              <Flex flexDirection={'column'}>
                <Text fontSize={'xs'} fontWeight={'normal'}>
                  план
                </Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {subscription.plan}
                </Text>
              </Flex>
              <Flex flexDirection={'column'}>
                <Text fontSize={'xs'} fontWeight={'normal'}>
                  тариф
                </Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {subscription.tier}
                </Text>
              </Flex>
              <Flex flexDirection={'column'}>
                <Text fontSize={'xs'} fontWeight={'normal'}>
                  стоимость
                </Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {((subscription.upcomingInvoice?.total ?? 0) / 100).toFixed(2)} руб.
                </Text>
              </Flex>
            </Flex>

            <Divider variant="dashed" my={3} />

            <Flex flexDirection={'column'} width="100%" alignItems={'flex-start'}>
              {subscription.portfolios.map((portfolio, i) => (
                <>
                  <PortfolioInSubscriptionCard
                    portfolio={portfolio}
                    price={
                      subscription.portfolioPriceSettings.find(
                        (p) => p.portfolioId === portfolio.id && subscription.tier === p.tier
                      )?.price
                    }
                  />
                  {i === subscription.portfolios.length - 1 ? null : <Box p={1} />}
                </>
              ))}
            </Flex>

            <Box p={4} />

            <Flex flexDirection={'row'} justifyContent={'space-between'}>
              <Flex flexDirection={'column'} justifyContent={'space-between'}>
                <Text fontSize={'xs'} fontWeight={'normal'}>
                  начало подписки
                </Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {new Date(subscription.subscribedAtTimestamp).toLocaleDateString('ru-RU', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </Text>
              </Flex>
              <Flex flexDirection={'column'} justifyContent={'space-between'}>
                <Text fontSize={'xs'} fontWeight={'normal'}>
                  следующий платеж
                </Text>
                <Text fontSize={'sm'} fontWeight={'bold'}>
                  {new Date(subscription.nextRenewalTimestamp).toLocaleDateString('ru-RU', {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <Center width={'100%'} height="xs">
          <Text fontSize={'xl'} fontWeight={'bold'} m={'auto'} mx={4} textAlign="center">
            Пользователь не имеет активной подписки
          </Text>
        </Center>
      )}
    </Flex>
  );
};

export interface UserCardProps {}

export const UserCard = (props: UserCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { id } = useParams();
  const [user, setUser] = useState<User | null>(null);
  const { isSuccess: isUserSuccess, refetch: refetchUser } = useQuery(['users'], () =>
    getUserById(id)
  );

  const cardBg = useColorModeValue('white', 'navy.800');

  const toggleBlockUser = useMutation(['users'], () =>
    user?.isInBlacklist
      ? unbanUser(id).then((r) => {
          if (r.data) {
            onClose();
            setUser(r.data);
          }
          return r;
        })
      : banUser(id).then((r) => {
          if (r.data) {
            onClose();
            setUser(r.data);
          }
          return r;
        })
  );

  useEffect(() => {
    refetchUser().then((data) => {
      if (data.data?.data) {
        setUser(data.data.data);
      }
    });
  }, [id]);

  if (!id) {
    return <div>Missing id</div>;
  }

  const renderUserStatRecord = (name: string, value?: string) => {
    return (
      <Flex flexDirection={'row'} justifyContent={'space-between'} mb={2} width={'100%'}>
        <Text fontSize={'sm'} fontWeight={'500'}>
          {name}:
        </Text>
        <Box p={2} />
        <Text fontSize={'sm'} fontWeight={'500'}>
          {value ?? ''}
        </Text>
      </Flex>
    );
  };

  return (
    <AppLayout>
      <Flex flexDirection={'row'} flexWrap="wrap">
        <Flex flexDirection={'column'}>
          {/* User card */}
          <Flex
            minWidth={'520px'}
            maxWidth={'600px'}
            height={'250px'}
            borderRadius={'8px'}
            boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
            bg={cardBg}
            flexGrow={1}>
            {!isUserSuccess ? (
              <Skeleton
                width={'100%'}
                height={'100%'}
                speed={2}
                opacity={0.9}
                borderRadius={'8px'}
                boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
              />
            ) : (
              <Flex
                flexDirection={'row'}
                width={'100%'}
                height={'100%'}
                py={4}
                px={8}
                justifyContent={'space-between'}>
                {/* Left side */}
                <Flex
                  flexDirection={'column'}
                  justifyContent={'space-around'}
                  alignItems={'center'}
                  my={'auto'}>
                  <Image
                    height="140px"
                    width="140px"
                    borderRadius={'50%'}
                    src={user?.avatar?.md ?? undefined}
                    alt="avatar"
                    fit="cover"
                    fallbackSrc={'https://avatars.mds.yandex.net/get-yapic/0/0-0/islands-200'}
                  />
                  <Box p={2} />
                  <Flex flexDirection={'column'} textAlign={'center'}>
                    <Text fontSize={'md'} fontWeight={'500'}>
                      @{user?.username ?? 'Anonymous'}
                    </Text>
                    <Text fontSize={'xl'} fontWeight={'bold'}>
                      {user?.firstName}&nbsp;{user?.surname}
                    </Text>
                  </Flex>
                </Flex>

                {/* Right side */}
                <Flex
                  height={'100%'}
                  my={'auto'}
                  ml={4}
                  flexDirection={'column'}
                  justifyContent={'space-between'}>
                  <Flex my={'auto'} flexDirection={'column'} alignItems={'flex-start'}>
                    {renderUserStatRecord('Идентификатор', user?.id)}
                    {renderUserStatRecord('Номер телефона', user?.phoneNumber)}
                    {renderUserStatRecord('Почта', user?.emailAddress)}
                    {renderUserStatRecord('Местонахождение', user?.location?.region)}
                    {renderUserStatRecord(
                      'Пол',
                      user?.gender === User_Gender.FEMALE
                        ? 'Женский'
                        : user?.gender === User_Gender.MALE
                        ? 'Мужской'
                        : 'Не указан'
                    )}
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>

          <Box p={4} />

          {/* User sessions */}
          <Flex minHeight={'250px'} flexDirection={'column'} fontSize={'xl'}>
            {user?.id ? (
              UserSessionsTable(user.id)
            ) : (
              <Skeleton
                width={'100%'}
                height={'100%'}
                speed={2}
                opacity={0.9}
                borderRadius={'8px'}
                boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
              />
            )}
          </Flex>
        </Flex>
        <Box p={4} />
        <Flex flexGrow={1}>
          <UserCardCurrentSubscription id={id} />
        </Flex>
        <Flex flexDirection="column">
          <Box p={4} />
          <Flex flexGrow={1}>
            <UserSubscription id={id} />
          </Flex>
          <Box p={4} />
          <Flex flexGrow={1}>
            <UserInvoices id={id} />
          </Flex>
        </Flex>
      </Flex>
      <Box p={4} />
      <Flex alignItems="center">
        <Button
          maxWidth={'320px'}
          _focus={{
            outline: 'none'
          }}
          colorScheme={user?.isInBlacklist ? 'green' : 'red'}
          isLoading={!user || toggleBlockUser.isLoading}
          onClick={onOpen}>
          {user?.isInBlacklist ? 'Разблокировать пользователя' : 'Заблокировать пользователя'}
        </Button>
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Вы действительно хотите {user?.isInBlacklist ? 'разблокировать ' : 'заблокировать'}{' '}
            пользователя?
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Отмена
            </Button>
            <Button
              colorScheme={user?.isInBlacklist ? 'green' : 'red'}
              onClick={() => toggleBlockUser.mutate()}>
              {user?.isInBlacklist ? 'Разблокировать ' : 'Заблокировать'}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </AppLayout>
  );
};
