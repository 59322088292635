import queryString from 'query-string';
import { isEmpty } from 'lodash';
import { AuthStorageItem } from '../constants';

enum Methods {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
interface BaseRequest {
  method?: string;
  headers?: Record<string, unknown>;
  body?: null | Record<string, unknown> | FormData | object[];
}
const fetchHeaders = new Headers({
  'Cache-Control': 'no-cache',
});

export const authHeaders = async (
  withoutAuth?: boolean,
): Promise<Record<string, string | undefined>> => {
  const authHeaderFromStorage = JSON.parse(localStorage.getItem(AuthStorageItem) as string);
  const headerObject = authHeaderFromStorage
    ? { Authorization: `Bearer ${authHeaderFromStorage}` }
    : {};
  return withoutAuth ? {} : headerObject;
};

export const generateHeaders = async (withoutAuth?: boolean) => {
  const headers = await authHeaders(withoutAuth);
  return {
    ...fetchHeaders,
    ...headers,
  };
};

const objectToQueryString = (queryObject: Record<string, unknown>): string =>
  queryString.stringify(queryObject, {
    arrayFormat: 'bracket',
    skipEmptyString: true,
    skipNull: true,
  });

const baseRequest = async (
  url: string,
  reqInit?: BaseRequest,
  withoutAuth?: boolean,
  withoutLogout?: boolean,
) => {
  try {
    const generatedHeaders = await generateHeaders(withoutAuth);
    const response = await fetch(url, {
      // mode: 'no-cors',
      ...reqInit,
      headers: {
        'Content-Type': 'application/json',
        ...generatedHeaders,
        ...reqInit?.headers,
      },
      body: reqInit?.body ? JSON.stringify(reqInit?.body) : undefined,
    });
    if (response.status === 401 && !withoutLogout) {
      localStorage.removeItem(AuthStorageItem);
      window.location.replace('/login');
    }
    const json = await response.json();

    return response.ok
      ? json
      : Promise.reject(new Error(JSON.stringify({ ...json, status: response.status })));
  } catch (e) {
    await Promise.reject(new Error(JSON.stringify({ message: 'Server error' })));
  }
};

export const postFileRequest = async <T>(url: string, data: FormData): Promise<T> => {
  const myHeaders = new Headers();
  const authHeaderFromStorage = JSON.parse(localStorage.getItem(AuthStorageItem) as string);
  myHeaders.append('Authorization', `Bearer ${authHeaderFromStorage}`);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: data,
  };

  const response = await fetch(url, requestOptions);
  if (response.status === 401) {
    localStorage.removeItem(AuthStorageItem);
    window.location.replace('/login');
  }
  const json = await response.json();
  return response.ok
    ? json
    : Promise.reject(new Error(JSON.stringify({ ...json, status: response.status })));
};

export const postRequest = <T>(
  url: string,
  body?: Record<string, unknown> | FormData,
  withoutAuth?: boolean,
  withoutLogout?: boolean,
): Promise<T> => baseRequest(url, { method: Methods.POST, body }, withoutAuth, withoutLogout);

export const patchRequest = <T>(
  url: string,
  body?: Record<string, unknown>,
  withoutLogout?: boolean,
): Promise<T> => baseRequest(url, { method: Methods.PATCH, body }, false, withoutLogout);

export const putRequest = <T>(
  url: string,
  body?: any,
  withoutLogout?: boolean,
): Promise<T> => baseRequest(url, { method: Methods.PUT, body }, false, withoutLogout);

export const deleteRequest = <T>(url: string, body?: Record<string, unknown>): Promise<T> =>
  baseRequest(url, { method: Methods.DELETE, body });

export const getRequest = <T>(
  url: string,
  query?: Record<string, unknown>,
  headers?: Record<string, string>,
  withoutAuth?: boolean,
): Promise<T> => {
  const reqUrl = isEmpty(query) || !query ? url : `${url}?${objectToQueryString(query)}`;
  return baseRequest(reqUrl, { headers, method: Methods.GET }, withoutAuth);
};
