import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import {
  Flex,
  useColorMode,
  useColorModeValue
} from "@chakra-ui/react";
import UserPopup from "components/Popups/UserPopup";
import { PropsWithChildren } from "react";

export interface AdminNavbarLinksProps {
  fixed: boolean;
  scrolled: boolean;
  onOpen: () => void;
}

export default function AdminNavbarLinks(props: PropsWithChildren<AdminNavbarLinksProps>) {
  const {
    fixed,
    scrolled,
  } = props;

  const { colorMode, toggleColorMode } = useColorMode();

  // Chakra Color Mode
  let navbarIcon = useColorModeValue(fixed && scrolled ? "gray.700" : "white", "gray.200")

  // let menuBg = useColorModeValue("white", "navy.800");
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems='center'
      flexDirection='row'>
      {/* <SearchBar me='18px' /> */}
      {/* <SettingsIcon
        cursor='pointer'
        ms={{ base: "16px", xl: "0px" }}
        me='16px'
        onClick={props.onOpen}
        color={navbarIcon}
        w='18px'
        h='18px'
      /> */}
      <UserPopup />
      {
        colorMode === "light" ?
          <MoonIcon
            w='18px'
            h='18px'
            cursor='pointer'
            ms={{ base: "16px", xl: "0px" }}
            me='16px'
            color={navbarIcon}
            onClick={toggleColorMode}
          />
          :
          <SunIcon
            w='18px'
            h='18px'
            cursor='pointer'
            ms={{ base: "16px", xl: "0px" }}
            me='16px'
            color={navbarIcon}
            onClick={toggleColorMode}
          />
      }
      {/* <Menu>
        <MenuButton>
          <BellIcon color={navbarIcon} w='18px' h='18px' />
        </MenuButton>
        <MenuList p='16px 8px' bg={menuBg}>
          <Flex flexDirection='column'>
            <MenuItem borderRadius='8px' mb='10px'>
              <ItemContent
                time='13 minutes ago'
                info='from Alicia'
                boldInfo='New Message'
                aName='Alicia'
                aSrc={avatar1}
              />
            </MenuItem>
            <MenuItem borderRadius='8px' mb='10px'>
              <ItemContent
                time='2 days ago'
                info='by Josh Henry'
                boldInfo='New Album'
                aName='Josh Henry'
                aSrc={avatar2}
              />
            </MenuItem>
            <MenuItem borderRadius='8px'>
              <ItemContent
                time='3 days ago'
                info='Payment succesfully completed!'
                boldInfo=''
                aName='Kara'
                aSrc={avatar3}
              />
            </MenuItem>
          </Flex>
        </MenuList>
      </Menu> */}
    </Flex>
  );
}