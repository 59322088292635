import { Box, Portal, Stack, useColorMode, useDisclosure } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import bgAdmin from "assets/img/admin-background.png";

import './styles.scss';
import Sidebar from 'components/Sidebar/Sidebar';
import { AppRoutes } from 'navigation/AppRoutes';
import MainPanel from 'components/Layout/MainPanel';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import { ArgonLogoDark, ArgonLogoLight, ChakraLogoDark, ChakraLogoLight } from 'components/Icons/Icons';
import { useCurrentRoute } from 'hooks/useCurrentRoute';
import PanelContent from 'components/Layout/PanelContent';
import PanelContainer from 'components/Layout/PanelContainer';

const AppLayout = ({ children }: PropsWithChildren<{}>) => {
  const { colorMode } = useColorMode();
  const { onOpen } = useDisclosure();

  const { route } = useCurrentRoute();

  return (
    <Box>
      <Box
        minH='40vh'
        w='100%'
        position='absolute'
        bg={colorMode === "light" ? bgAdmin : "navy.900"}
        bgImage={colorMode === "light" ? bgAdmin : "none"}
        bgSize='cover'
        top='0'
      />
      <Sidebar
        routes={AppRoutes}
        logo={
          <Stack direction='row' spacing='12px' align='center' justify='center'>
            {colorMode === "dark" ? (
              <ArgonLogoLight w='74px' h='27px' />
            ) : (
              <ArgonLogoDark w='74px' h='27px' />
            )}
            <Box
              w='1px'
              h='20px'
              bg={colorMode === "dark" ? "white" : "gray.700"}
            />
            {colorMode === "dark" ? (
              <ChakraLogoLight w='82px' h='21px' />
            ) : (
              <ChakraLogoDark w='82px' h='21px' />
            )}
          </Stack>
        }
      />
      <MainPanel
        w={'calc(100% - 275px)'}
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            brandText={route.name}
            brandTextLink={route.path.replaceAll(/\/:\S+/g, '')}
            fixed={false}
          />
          <PanelContent
            position='absolute'
            right={"30px"}
            px={'3opx'}
            ps={'12px'}
            top="75px"
            w={"calc(100vw - 75px - 275px)"}
          >
            <PanelContainer>
              {children}
            </PanelContainer>
          </PanelContent>
        </Portal>
        {/* <Configurator
          secondary={getActiveNavbar(routes)}
          isOpen={isOpen}
          onClose={onClose}
          isChecked={fixed}
          onSwitch={(value) => {
            setFixed(value);
          }}
        /> */}
      </MainPanel>
    </Box>
  );
};

export default AppLayout;
