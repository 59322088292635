import AppLayout from "layouts/AppLayout";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

import { AppRoutes, FlatAppRoutes } from "./AppRoutes";
import PrivateRoute from "./PrivateRoute";

const ApplicationRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path="/"
        key={"default"}
        element={<Navigate to={AppRoutes.Dashboard.path} />}
      />
      {FlatAppRoutes.filter((route) => route.name !== AppRoutes.Login.name).map((route) => (
        <Route
          path={route.path}
          element={<PrivateRoute>{route.element}</PrivateRoute>}
          key={route.path}
        />
      ))}
      <Route path={AppRoutes.Login.path} element={AppRoutes.Login.element} />
      <Route
        path="*"
        element={
          <AppLayout>
            <main style={{ padding: "1rem" }}>
              <p>Not Found</p>
            </main>
          </AppLayout>
        }
      />
    </Routes>
  </BrowserRouter>
);

export default ApplicationRouter;
