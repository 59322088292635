import { CheckIcon } from '@chakra-ui/icons';
import { CellItem } from '../Table';

const CheckCell = (props: CellItem) => {
  const { value } = props;

  return <>{value === 'active' ? <CheckIcon /> : <>-</>}</>;
};

export default CheckCell;
