import React from 'react';
import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../state/AuthContext';
import { AppRoutes } from './AppRoutes';

const PrivateRoute = ({ children }: PropsWithChildren<any>): JSX.Element | null => {
  const { loggedIn } = useAuth();
  let location = useLocation();

  if (!loggedIn) {
    return <Navigate to={AppRoutes.Login.path} state={{ from: location }} replace />;
  }

  return children ?? null;
};

export default PrivateRoute;
