import { Box, Button, Flex, Stack, Text, useColorModeValue } from '@chakra-ui/react';

import { PropsWithChildren } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { NavLink, useLocation } from 'react-router-dom';

import {
  renderThumbDark,
  renderThumbLight,
  renderTrack,
  renderView
} from 'components/Scrollbar/Scrollbar';

import { AppRoute } from 'navigation/AppRoutes';
import IconBox from 'components/Icons/IconBox';

export interface SidebarProps {
  routes: Record<string, AppRoute>;
  logo: JSX.Element;
}

function Sidebar(props: PropsWithChildren<SidebarProps>) {
  // to check for active links and opened collapses
  let location = useLocation();

  // verifies if routeName is the one active (in browser input)
  const isActiveRoute = (routeName: string) => {
    const routeParts = routeName.split('/');
    const locationParts = location.pathname.split('/');

    if (routeParts.length !== locationParts.length) {
      return false;
    }

    for (let i = 0; i < routeParts.length; i++) {
      if (routeParts[i].startsWith(':')) {
        continue;
      }
      if (routeParts[i] !== locationParts[i]) {
        return false;
      }
    }

    return true;
  };

  const activeBg = useColorModeValue('white', 'navy.700');
  const inactiveBg = useColorModeValue('white', 'navy.700');
  const activeColor = useColorModeValue('gray.700', 'white');
  const inactiveColor = useColorModeValue('gray.400', 'white');
  const sidebarActiveShadow = '0px 7px 11px rgba(0, 0, 0, 0.04)';

  const createLinks = (routes: Record<string, AppRoute>, basePath = '') => {
    return Object.entries(routes)
      .filter(([, route]) => !route.hidden)
      .map(([routeName, route]): JSX.Element => {
        if (
          route.children &&
          Object.values(route.children).reduce((acc, child) => acc || !child.hidden, false)
        ) {
          return (
            <>
              <Flex my={'16px'} px={'16px'}>
                <IconBox bg={activeBg} color="blue.500" h="30px" w="30px" me="12px">
                  {route.icon ?? <></>}
                </IconBox>
                <Text color={activeColor} fontWeight="bold" my={'auto'}>
                  {route.name}
                </Text>
              </Flex>
              <Box ml={'42px'}>{createLinks(route.children, route.path)}</Box>
            </>
          );
        }

        return (
          <NavLink to={basePath + route.path} key={basePath + route.path}>
            {isActiveRoute(basePath + route.path) ||
            Object.values(route.children ?? []).reduce(
              (acc, r) => acc || isActiveRoute(basePath + route.path + r.path),
              false
            ) ? (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                boxShadow={sidebarActiveShadow}
                bg={activeBg}
                mb={'6px'}
                mx={'auto'}
                ps={'16px'}
                py="12px"
                borderRadius="15px"
                w="100%"
                _active={{
                  bg: 'inherit',
                  transform: 'none',
                  borderColor: 'transparent'
                }}
                _focus={{
                  boxShadow: '0px 7px 11px rgba(0, 0, 0, 0.04)'
                }}>
                <Flex>
                  <IconBox
                    display={basePath === '' ? undefined : 'none'}
                    bg={activeBg}
                    color="blue.500"
                    h="30px"
                    w="30px"
                    me="12px">
                    {route.icon ?? <></>}
                  </IconBox>
                  <Text color={activeColor} my="auto" fontSize="sm">
                    {route.name}
                  </Text>
                </Flex>
              </Button>
            ) : (
              <Button
                boxSize="initial"
                justifyContent="flex-start"
                alignItems="center"
                bg="transparent"
                mb={'6px'}
                mx={'auto'}
                py="12px"
                ps={'16px'}
                borderRadius="15px"
                w="100%"
                _active={{
                  bg: 'inherit',
                  transform: 'none',
                  borderColor: 'transparent'
                }}
                _focus={{
                  boxShadow: 'none'
                }}>
                <Flex>
                  <IconBox
                    display={basePath === '' ? undefined : 'none'}
                    bg={inactiveBg}
                    color="blue.500"
                    h="30px"
                    w="30px"
                    me="12px">
                    {route.icon ?? <></>}
                  </IconBox>
                  <Text color={inactiveColor} my="auto" fontSize="sm">
                    {route.name}
                  </Text>
                </Flex>
              </Button>
            )}
          </NavLink>
        );
      });
  };

  const { routes } = props;

  const sidebarBg = useColorModeValue('white', 'navy.800');
  const sidebarRadius = '20px';
  const brand = (
    <Box pt={'25px'} mb="12px">
      {/* {logo} */}
      {/* <HSeparator my="26px" /> */}
    </Box>
  );

  return (
    <Box>
      <Box
        display={'block'}
        position="fixed"
        bg={sidebarBg}
        w="260px"
        marginLeft={'16px'}
        my={'16px'}
        h="calc(100vh - 32px)"
        px={'20px'}
        filter="drop-shadow(0px 5px 14px rgba(0, 0, 0, 0.05))"
        borderRadius={sidebarRadius}>
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={useColorModeValue(renderThumbLight, renderThumbDark)}
          renderView={renderView}>
          <Box>{brand}</Box>
          <Stack direction="column" mb="40px">
            <Box>{createLinks(routes)}</Box>
          </Stack>
        </Scrollbars>
      </Box>
    </Box>
  );
}

export default Sidebar;
