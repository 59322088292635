import { QUERY_USERS } from 'data/endpoints';
import { User, UsersIteratorFilter } from 'data/User';
import { getRequest } from 'utils/baseRequest';

export interface QueryUsersTypes {
  page: number;
  users: User[];
}

export const getQueryUsers = async (page?: number, filter?: UsersIteratorFilter) => {
  try {
    const payload = {
      page,
      ...filter
    };
    const response = await getRequest<QueryUsersTypes>(QUERY_USERS, payload);

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false, data: undefined };
  }
};
