import { FlatAppRoutes } from "navigation/AppRoutes";
import { useLocation } from "react-router-dom";

export const useCurrentRoute = () => {
  const isActiveRoute = (routeName: string) => {
    const routeParts = routeName.split("/");
    const locationParts = location.pathname.split("/");

    if (routeParts.length !== locationParts.length) {
      return false;
    }

    for (let i = 0; i < routeParts.length; i++) {
      if (routeParts[i].startsWith(":")) {
        continue;
      }
      if (routeParts[i] !== locationParts[i]) {
        return false;
      }
    }

    return true;
  };

  const location = useLocation();
  const routes = Object.values(FlatAppRoutes);
  const currentRoute = routes.find((route) => isActiveRoute(route.path) || Object.values(route.children ?? []).reduce((acc, r) => acc || isActiveRoute(route.path + r.path), false)) ?? FlatAppRoutes[0];
  return { route: currentRoute };
}
