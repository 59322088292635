import { Image } from '@chakra-ui/react';
import { CellItem } from '.';

const ImageCell = (props: CellItem & { value: File }) => {
  const {
    value,
    row: { original }
  } = props;

  return value ? (
    <Image src={value} alt={original.name} width={38} height={38} borderRadius={9} margin="auto" />
  ) : null;
};

export default ImageCell;
