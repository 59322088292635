import { INVOICES_BY_ID, SUBSCRIPTIONS, USER_BY_ID } from 'data/endpoints';
import { Portfolio } from 'data/Portfolio';
import {
  Invoice,
  PortfolioPriceSettings,
  Subscription,
  SubscriptionPlanSettings
} from 'data/Subscription';
import { getRequest, putRequest } from 'utils/baseRequest';

export const getCurrentSubscriptionByUserId = async (id?: string) => {
  if (!id) {
    return { ok: false, data: undefined };
  }

  try {
    const response = await getRequest<Subscription>(USER_BY_ID(id) + '/subscriptions/current');

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false, data: undefined };
  }
};

interface getinvoicesData {
  invoices: Invoice[];
}

export const getCurrentUserInvoicesByUserId = async (id?: string) => {
  if (!id) {
    return { ok: false, data: undefined };
  }

  try {
    const response = await getRequest<getinvoicesData>(INVOICES_BY_ID(id));

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false, data: undefined };
  }
};

export interface GetSubscriptionSettingsResponse {
  subscriptionPlanSettings: SubscriptionPlanSettings[];
  portfolioPriceSettings: (PortfolioPriceSettings & { portfolio: Portfolio })[];
}

export const getSubscriptionSettings = async () => {
  try {
    const response = await getRequest<GetSubscriptionSettingsResponse>(SUBSCRIPTIONS + '/settings');

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false, data: undefined };
  }
};

/** You cannot delete settings, only update or create. */
export interface UpdateSubscriptionSettingsRequest {
  /**
   * Will update (PUT like) or create (POST like) only passed settings.
   * And will not affect other settings.
   */
  subscriptionPlanSettings: SubscriptionPlanSettings[];
  /**
   * Will update (PUT like) or create (POST like) only passed settings.
   * And will not affect other settings.
   */
  portfolioPriceSettings: PortfolioPriceSettings[];
}

export const updateSubscriptionSettings = async (settings: UpdateSubscriptionSettingsRequest) => {
  try {
    const response = await putRequest<GetSubscriptionSettingsResponse>(
      SUBSCRIPTIONS + '/update-settings',
      settings
    );

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false, data: undefined };
  }
};

export interface GetAllPortfoliosResponse {
  portfolios: Portfolio[];
}

export const getAllPortfolios = async () => {
  try {
    const response = await getRequest<GetAllPortfoliosResponse>(SUBSCRIPTIONS + '/portfolios');

    return { ok: true, data: response };
  } catch (e) {
    return { ok: false, data: undefined };
  }
};
