import { Text } from '@chakra-ui/react';
import { CellItem } from '.';

const DateCell = (props: CellItem) => {
  const { value } = props;

  return (
    <Text width="max-content" my="auto" fontWeight={400} color="inherit">
      {value}
    </Text>
  );
};

export default DateCell;
