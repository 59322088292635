import './styles.scss';
import {
  Flex,
  Skeleton,
  useColorModeValue,
  Text,
  Box,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Switch,
  Select,
  Button,
  Td,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Input,
  useToast
} from '@chakra-ui/react';
import { Portfolio } from 'data/Portfolio';
import {
  DurationUnit,
  PortfolioPriceSettings,
  SubscriptionPlanSettings,
  SubscriptionTier
} from 'data/Subscription';
import AppLayout from 'layouts/AppLayout';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  getAllPortfolios,
  getSubscriptionSettings,
  updateSubscriptionSettings,
  UpdateSubscriptionSettingsRequest
} from 'repository/subscriptions';
import DiscountTable from 'components/DiscountTable';

export const EditableNumberCell = (props: {
  isPercent?: boolean;
  value?: number;
  onChange: (value: number) => void;
}) => {
  const inputBg = useColorModeValue('#fff', '#111c44');

  return (
    <Flex alignItems="center" backgroundColor={inputBg}>
      <Input
        className="portfolio-table__input"
        placeholder="Введите значение"
        value={props.value}
        type="number"
        onWheel={(e) => e.currentTarget.blur()}
        onChange={(e) => {
          props.onChange(Number(e.target.value));
        }}
      />
      <Box width="30px" textAlign="left">
        {props.isPercent ? ' %' : ' руб.'}
      </Box>
    </Flex>
  );
};

export const Subscriptions = () => {
  const toast = useToast();
  const [subscriptionPlanSettings, setSubscriptionPlanSettings] = useState<
    SubscriptionPlanSettings[]
  >([]);
  const [portfolioPriceSettings, setPortfolioPriceSettings] = useState<PortfolioPriceSettings[]>(
    []
  );
  const [portfolios, setPortfolios] = useState<Portfolio[]>([]);
  const { isSuccess: isSettingsSuccess } = useQuery(['subscription-settings'], () =>
    getSubscriptionSettings().then((resp) => {
      setSubscriptionPlanSettings([]);
      setPortfolioPriceSettings([]);
      if (resp.data) {
        setSubscriptionPlanSettings(
          resp.data.subscriptionPlanSettings.sort((a, b) => a.minPortfolios - b.minPortfolios)
        );
        setPortfolioPriceSettings(resp.data.portfolioPriceSettings);
      }
    })
  );
  const { isSuccess: isPortfolioSuccess } = useQuery(['portfolios'], () =>
    getAllPortfolios().then((resp) => {
      setPortfolios([]);
      if (resp.data) {
        setPortfolios(resp.data.portfolios);
      }
    })
  );

  const saveSettings = useMutation(
    ['subscription-settings'],
    (settings: UpdateSubscriptionSettingsRequest) =>
      updateSubscriptionSettings(settings).then((data) => {
        if (data.ok) {
          if (data.data) {
            setSubscriptionPlanSettings(
              data.data.subscriptionPlanSettings.sort((a, b) => a.minPortfolios - b.minPortfolios)
            );
            setPortfolioPriceSettings(data.data.portfolioPriceSettings);
            toast({
              title: 'Данные сохранены!',
              status: 'success',
              duration: 2000,
              isClosable: true
            });
          }
        } else {
          toast({
            title: 'Ошибка!',
            status: 'error',
            duration: 2000,
            isClosable: true
          });
        }
      })
  );

  const cardBg = useColorModeValue('white', 'navy.800');

  return (
    <AppLayout>
      <Flex flexDirection={'column'}>
        <Flex flexGrow={1} minH="250px" mb="15px">
          {!isSettingsSuccess ? (
            <Skeleton
              width={'100%'}
              minH={'100%'}
              speed={2}
              opacity={0.9}
              borderRadius={'8px'}
              boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
            />
          ) : (
            <Flex width={'100%'} flexDirection={'column'}>
              <Flex width={'100%'} flexDirection={'row'} justifyContent={'space-between'}>
                {subscriptionPlanSettings.map((plan, i) => (
                  <Flex
                    flexDirection={'column'}
                    width={'320px'}
                    borderRadius={'8px'}
                    boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
                    p={4}
                    bg={cardBg}>
                    <Flex alignItems={'center'} w={'100%'}>
                      <Text mx={'auto'} fontSize={'xl'} fontWeight={'bold'}>
                        {plan.plan}
                      </Text>
                    </Flex>
                    <Box p={2} />
                    <Flex flexDirection={'column'}>
                      <Text fontSize={'xs'} fontWeight={'normal'}>
                        Скидка предоставляемая на пакет в <b>%</b> от общей стоимости
                      </Text>
                      <Box p={1} />
                      <Flex mx={2}>
                        <NumberInput
                          size={'sm'}
                          max={100}
                          min={0}
                          step={1}
                          width={'100%'}
                          value={plan.discount}
                          onChange={(value) => {
                            setSubscriptionPlanSettings((prev) => {
                              return prev.map((item) => {
                                if (item.plan === plan.plan) {
                                  return { ...item, discount: +value };
                                }
                                return item;
                              });
                            });
                          }}>
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Flex>
                    </Flex>
                    <Box p={2} />
                    <Flex flexDirection={'column'}>
                      <Text fontSize={'xs'} fontWeight={'normal'}>
                        Максимальная скидка на портфели в пакете
                      </Text>
                      <Box p={1} />
                      <Flex mx={2}>
                        <NumberInput
                          size={'sm'}
                          max={100}
                          min={0}
                          step={1}
                          width={'100%'}
                          value={plan.portfoliosMaxDiscount}
                          onChange={(value) => {
                            setSubscriptionPlanSettings((prev) => {
                              return prev.map((item) => {
                                if (item.plan === plan.plan) {
                                  return { ...item, portfoliosMaxDiscount: +value };
                                }
                                return item;
                              });
                            });
                          }}>
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Flex>
                    </Flex>
                    <Box p={2} />
                    <Flex flexDirection={'column'}>
                      <Text fontSize={'xs'} fontWeight={'normal'}>
                        Диапазон количества портфелей в пакете
                        <br />
                        <Text
                          display={i === 0 ? 'none' : 'block'}
                          fontSize={'10'}
                          fontWeight={'normal'}
                          color={'gray.500'}>
                          Минимальное количество портфелей всегда равно +1 от максимального
                          количества портфелей предыдущего пакета
                        </Text>
                      </Text>
                      <Box p={1} />
                      <Flex mx={2}>
                        <NumberInput
                          isDisabled={true}
                          size={'sm'}
                          min={0}
                          step={1}
                          width={'100%'}
                          value={plan.minPortfolios}>
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                        <Box p={2} />
                        <NumberInput
                          size={'sm'}
                          min={plan.minPortfolios}
                          step={1}
                          width={'100%'}
                          value={plan.maxPortfolios}
                          onChange={(_, value) => {
                            setSubscriptionPlanSettings((prev) => {
                              return prev.map((item, i, items) => {
                                if (item.plan === plan.plan) {
                                  return { ...item, maxPortfolios: value };
                                }
                                return {
                                  ...item,
                                  minPortfolios: i > 0 ? items[i - 1].maxPortfolios + 1 : 0,
                                  maxPortfolios: Math.max(
                                    item.maxPortfolios,
                                    i > 0 ? items[i - 1].maxPortfolios + 1 : 0
                                  )
                                };
                              });
                            });
                          }}>
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </Flex>
                    </Flex>

                    <Box p={2} />

                    <Flex flexDirection={'column'}>
                      <Text fontSize={'xs'} fontWeight={'normal'}>
                        Включить пробный период для этого пакета?
                      </Text>
                      <Box p={1} />

                      <Flex
                        h={8}
                        mx={2}
                        textAlign={'center'}
                        verticalAlign={'middle'}
                        flexDirection={'row'}
                        justifyContent={'space-between'}>
                        <Switch
                          my={'auto'}
                          isChecked={plan.isTrialEnabled}
                          size="md"
                          onChange={(event) => {
                            const turnOn = event.target.checked;
                            setSubscriptionPlanSettings((prev) => {
                              return prev.map((item) => {
                                if (item.plan === plan.plan) {
                                  return {
                                    ...item,
                                    isTrialEnabled: turnOn,
                                    trialDuration: turnOn ? item.trialDuration : undefined,
                                    trialDurationUnit: turnOn ? item.trialDurationUnit : undefined
                                  };
                                }
                                return item;
                              });
                            });
                          }}
                        />
                        {plan.isTrialEnabled && (
                          <Flex>
                            <NumberInput
                              maxW={20}
                              size={'xs'}
                              min={1}
                              defaultValue={1}
                              step={1}
                              my={'auto'}
                              onChange={(_, value) => {
                                setSubscriptionPlanSettings((prev) => {
                                  return prev.map((item) => {
                                    if (item.plan === plan.plan) {
                                      return { ...item, trialDuration: value };
                                    }
                                    return item;
                                  });
                                });
                              }}
                              value={plan.trialDuration}>
                              <NumberInputField />
                              <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                              </NumberInputStepper>
                            </NumberInput>
                            <Box p={2} />
                            <Select
                              maxW={40}
                              top={'-1px'}
                              my={'auto'}
                              size={'xs'}
                              onChange={(event) => {
                                setSubscriptionPlanSettings((prev) => {
                                  return prev.map((item) => {
                                    if (item.plan === plan.plan) {
                                      return {
                                        ...item,
                                        trialDurationUnit: event.currentTarget.value as DurationUnit
                                      };
                                    }
                                    return item;
                                  });
                                });
                              }}
                              value={plan.trialDurationUnit}>
                              {Object.values(DurationUnit)
                                .filter((v) => v !== DurationUnit.UNRECOGNIZED)
                                .map((unit) => (
                                  <option value={unit}>{unit}</option>
                                ))}
                            </Select>
                          </Flex>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                ))}
              </Flex>
              <Box p={2} />

              <Flex
                flexDirection={'column'}
                width={'100%'}
                borderRadius={'8px'}
                boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
                bg={cardBg}>
                {!isPortfolioSuccess ? (
                  <Skeleton
                    width={'100%'}
                    height={'500px'}
                    speed={2}
                    opacity={0.9}
                    borderRadius={'8px'}
                    boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
                  />
                ) : (
                  <TableContainer className="portfolio-table" p={4}>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Публичность</Th>
                          <Th>Доступность</Th>
                          <Th>Портфель</Th>
                          <Th isNumeric>Скидка</Th>
                          <Th isNumeric>Цена за месяц</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {portfolios.map((portfolio) => {
                          const monthlyPrice = portfolioPriceSettings.find(
                            (p) =>
                              p.portfolioId === portfolio.id && p.tier === SubscriptionTier.MONTHLY
                          );
                          const yearlyPrice = portfolioPriceSettings.find(
                            (p) =>
                              p.portfolioId === portfolio.id && p.tier === SubscriptionTier.YEARLY
                          );
                          return (
                            <Tr
                              opacity={monthlyPrice?.isEnabled && yearlyPrice?.isEnabled ? 1 : 0.5}>
                              <Td>
                                <Switch
                                  isDisabled={!portfolio.stateId}
                                  isChecked={monthlyPrice?.isPublic && yearlyPrice?.isPublic}
                                  onChange={(event) => {
                                    setPortfolioPriceSettings((prev) => {
                                      return prev.map((item) => {
                                        if (item.portfolioId === portfolio.id) {
                                          return { ...item, isPublic: event.target.checked };
                                        }
                                        return item;
                                      });
                                    });
                                  }}
                                />
                              </Td>
                              <Td>
                                <Switch
                                  isDisabled={!portfolio.stateId}
                                  isChecked={monthlyPrice?.isEnabled && yearlyPrice?.isEnabled}
                                  onChange={(event) => {
                                    setPortfolioPriceSettings((prev) => {
                                      return prev.map((item) => {
                                        if (item.portfolioId === portfolio.id) {
                                          return { ...item, isEnabled: event.target.checked };
                                        }
                                        return item;
                                      });
                                    });
                                  }}
                                />
                              </Td>
                              <Td>{portfolio.name}</Td>
                              <Td>
                                <EditableNumberCell
                                  value={
                                    monthlyPrice && monthlyPrice?.discount
                                      ? monthlyPrice.discount
                                      : undefined
                                  }
                                  isPercent
                                  onChange={(value) => {
                                    setPortfolioPriceSettings((prev) => {
                                      if (!prev.find((p) => p.portfolioId === portfolio.id)) {
                                        return [
                                          ...prev,
                                          {
                                            discount: value,
                                            portfolioId: portfolio.id,
                                            tier: SubscriptionTier.MONTHLY,
                                            price: Math.floor(
                                              prev.find((item) => item.portfolioId === portfolio.id)
                                                ?.price || 0 * 100
                                            ),
                                            isEnabled:
                                              prev.find((item) => item.portfolioId === portfolio.id)
                                                ?.isEnabled || false,
                                            isPublic:
                                              prev.find((item) => item.portfolioId === portfolio.id)
                                                ?.isPublic || false
                                          }
                                        ];
                                      }
                                      return prev.map((item) => {
                                        if (item.portfolioId === portfolio.id) {
                                          return { ...item, discount: value };
                                        }
                                        return item;
                                      });
                                    });
                                  }}
                                />
                              </Td>
                              <Td isNumeric>
                                <EditableNumberCell
                                  value={
                                    monthlyPrice && monthlyPrice?.price
                                      ? monthlyPrice.price / 100
                                      : undefined
                                  }
                                  onChange={(value) => {
                                    setPortfolioPriceSettings((prev) => {
                                      if (
                                        !prev.find(
                                          (p) =>
                                            p.portfolioId === portfolio.id &&
                                            p.tier === SubscriptionTier.MONTHLY
                                        )
                                      ) {
                                        return [
                                          ...prev,
                                          {
                                            discount:
                                              prev.find((item) => item.portfolioId === portfolio.id)
                                                ?.discount || 0,
                                            portfolioId: portfolio.id,
                                            tier: SubscriptionTier.MONTHLY,
                                            price: Math.floor(value * 100),
                                            isEnabled:
                                              prev.find((item) => item.portfolioId === portfolio.id)
                                                ?.isEnabled || false,
                                            isPublic:
                                              prev.find((item) => item.portfolioId === portfolio.id)
                                                ?.isPublic || false
                                          }
                                        ];
                                      }
                                      return prev.map((item) => {
                                        if (
                                          item.portfolioId === portfolio.id &&
                                          item.tier === SubscriptionTier.MONTHLY
                                        ) {
                                          return { ...item, price: Math.floor(value * 100) };
                                        }
                                        return item;
                                      });
                                    });
                                  }}
                                />
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                )}
              </Flex>
              <Box p={2} />
              <Button
                maxW={'500px'}
                _focus={{
                  outline: 'none'
                }}
                colorScheme={'green'}
                m={'auto'}
                isLoading={saveSettings.isLoading}
                onClick={() =>
                  saveSettings.mutate({
                    portfolioPriceSettings: portfolioPriceSettings.map((item) => ({
                      portfolioId: item.portfolioId,
                      tier: item.tier,
                      discount: item.discount,
                      price: item.price,
                      isEnabled: item.isEnabled,
                      isPublic: item.isPublic
                    })),
                    subscriptionPlanSettings: subscriptionPlanSettings
                  })
                }>
                Сохранить текущие настройки
              </Button>
            </Flex>
          )}
        </Flex>
        <DiscountTable />
      </Flex>
    </AppLayout>
  );
};
