import { QueryRenderer, QueryRendererRenderProps } from '@cubejs-client/react';
import { ResultSet, Query } from '@cubejs-client/core';

import { Flex, FlexProps, useColorModeValue, Text, Skeleton, Stack, Box } from '@chakra-ui/react';
import IconBox from 'components/Icons/IconBox';
import { useCube } from 'hooks/useCube';
import { useAuth } from 'state/AuthContext';

export interface PivotConfig {
  x: string[];
  y: string[];
  fillMissingDates?: boolean;
  joinDateRange?: boolean;
}

export interface InlineChartRendererProps extends FlexProps {
  chartProps: {
    id: string;
    query: Query | Query[];
    pivotConfig: PivotConfig;
    valueProvider: (props: { resultSet: ResultSet; pivotConfig: PivotConfig }) => {
      value: string | number;
      previousValue?: string | number;
      // Number in fraction format e.g. 50% = 0.5
      percentageChange?: number;
    };

    title: string;
    percentageChangeText?: string;
    icon?: JSX.Element;
  };
}

export const InlineChartRenderer = ({ chartProps, ...rest }: InlineChartRendererProps) => {
  const { logOut } = useAuth();
  const cardBg = useColorModeValue('white', 'navy.800');
  const titleText = useColorModeValue('gray.400', 'gray.200');
  const cube = useCube();

  const renderChart = ({
    resultSet,
    error,
    pivotConfig,
    valueProvider
  }: QueryRendererRenderProps & {
    pivotConfig: PivotConfig;
    valueProvider: (props: { resultSet: ResultSet; pivotConfig: PivotConfig }) => {
      value: string | number;
      previousValue?: string | number;
      // Number in fraction format e.g. 50% = 0.5
      percentageChange?: number;
    };
  }) => {
    if (error) {
      if (error.toString() === 'Error: Invalid token') {
        logOut();
      }

      return <div>{error.toString()}</div>;
    }

    if (!resultSet) {
      return (
        <Skeleton
          width={'100%'}
          height={'100%'}
          speed={2}
          opacity={0.9}
          borderRadius={'8px'}
          boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
          {...rest}
        />
      );
    }

    const value = valueProvider({ resultSet, pivotConfig });

    return (
      <Flex
        flexDirection={'column'}
        justifyContent={'space-between'}
        px={'20px'}
        py={'16px'}
        width={'100%'}
        height={'100%'}
        borderRadius={'8px'}
        boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
        bg={cardBg}>
        <Flex flexDirection={'row'} justifyContent={'space-between'}>
          <Stack>
            <Text
              textColor={titleText}
              my={'auto'}
              display={'block'}
              width={'100%'}
              bg="inherit"
              borderRadius="inherit"
              fontSize={'sm'}
              fontWeight="bold"
              textTransform="uppercase"
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent'
              }}
              _focus={{
                boxShadow: 'none'
              }}>
              {chartProps.title}
            </Text>
            <Text
              mt={'0px!important'}
              fontSize={'lg'}
              display={'block'}
              width={'100%'}
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              _active={{
                bg: 'inherit',
                transform: 'none',
                borderColor: 'transparent'
              }}
              _focus={{
                boxShadow: 'none'
              }}>
              {value.value}
            </Text>
          </Stack>
          <IconBox w="30px" h="30px" bg="blue.500" color="white" textAlign="center">
            {chartProps.icon}
          </IconBox>
        </Flex>
        <Box p={0.5} />
        <Text
          textColor={titleText}
          display={'block'}
          width={'100%'}
          bg="inherit"
          borderRadius="inherit"
          fontSize={'sm'}
          fontWeight="400"
          _active={{
            bg: 'inherit',
            transform: 'none',
            borderColor: 'transparent'
          }}
          _focus={{
            boxShadow: 'none'
          }}>
          {value.percentageChange !== undefined && chartProps.percentageChangeText ? (
            <>
              <Text as="span" textColor={value.percentageChange >= 0 ? 'green' : 'red'}>
                {value.percentageChange >= 0 ? '+' : ''}
                {(value.percentageChange * 100).toFixed(2)}%
                {typeof value.value === 'number' && typeof value.previousValue === 'number'
                  ? `(${value.percentageChange >= 0 ? '+' : ''}${
                      value.value - value.previousValue
                    })`
                  : ''}
                &nbsp;
              </Text>
              {chartProps.percentageChangeText}
            </>
          ) : (
            <></>
          )}
        </Text>
      </Flex>
    );
  };

  return (
    <Flex
      borderRadius={'8px'}
      boxShadow={'0px 0px 8px rgba(0, 0, 0, 0.1)'}
      width={300}
      flexShrink={0}
      height={115}
      {...rest}>
      <QueryRenderer
        query={chartProps.query}
        cubejsApi={cube}
        resetResultSetOnChange={false}
        render={(props) =>
          renderChart({
            ...props,
            pivotConfig: chartProps.pivotConfig,
            valueProvider: chartProps.valueProvider
          })
        }
      />
    </Flex>
  );
};
